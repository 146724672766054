<div>
    <div class="space-y-8">
        <div class="panel flex items-center overflow-x-auto whitespace-nowrap p-3 text-primary lg:col-span-2">
            <div class="rounded-full bg-primary p-1.5 text-white ring-2 ring-primary/30 ltr:mr-3 rtl:ml-3">
                <icon-bell />
            </div>
            <span class="ltr:mr-3 rtl:ml-3">Documentation: </span>
            <a
                href="https://www.figma.com/file/agsPUbJSO4OcokUIxJRZvw/Solar-Icon-Set-(Community)?node-id=0%3A1&t=Xr5s4CqZbVgAQU9X-0"
                target="_blank"
                class="block hover:underline"
                >https://www.figma.com/file/agsPUbJSO4OcokUIxJRZvw/Solar-Icon-Set-(Community)?node-id=0%3A1&t=Xr5s4CqZbVgAQU9X-0</a
            >
        </div>
        <div class="panel">
            <h5 class="mb-5 text-lg font-semibold dark:text-white-light">Solar Icon</h5>
            <div class="mb-5">
                <p class="mb-5">
                    Solar is a collection of simply beautiful open source icons. Each icon is designed on a 24x24 grid with an emphasis on simplicity,
                    consistency and usability.
                </p>

                <div class="mb-5 inline-block rounded bg-[#009688]/[.26] px-2 py-1 text-base text-[#009688]">Line Duotone</div>
                <div class="mb-5 flex flex-wrap items-center gap-10">
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-airplay class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-archive class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-arrow-backward class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-arrow-forward class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-arrow-left class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-at class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-award class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-bar-chart class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-bell class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-bell-bing class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-bolt class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-book class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-bookmark class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-box class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-calendar class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-camera class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-caret-down class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-carets-down class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-cash-banknotes class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-chart-square class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-chat-dot class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-chat-dots class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-chat-notification class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-checks class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-chrome class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-circle-check class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-clipboard-text class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-clock class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-cloud-download class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-code class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-coffee class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-copy class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-cpu-bolt class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-credit-card class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-desktop class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-dollar-sign class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-dollar-sign-circle class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-download class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-dribbble class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-droplet class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-edit class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-info-circle class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-eye class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-facebook class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-file class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-folder class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-folder-minus class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-folder-plus class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-gallery class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-github class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-globe class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-heart class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-help-circle class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-home class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-horizontal-dots class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-inbox class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-info-hexagon class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-info-triangle class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-instagram class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-laptop class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-layout class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-layout-grid class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-link class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-linkedin class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-list-check class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-loader class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-lock class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-lock-dots class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-login class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-logout class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-mail class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-mail-dot class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-map-pin class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-message class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-message-2 class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-message-dots class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-messages-dot class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-microphone-off class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-minus class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-minus-circle class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-mood-smile class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-moon class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-multiple-forward-right class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-notes class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-notes-edit class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-open-book class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-paperclip class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-pencil class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-pencil-paper class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-phone class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-phone-call class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-play-circle class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-plus class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-plus-circle class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-printer class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-refresh class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-restore class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-router class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-safari class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-save class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-search class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-send class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-server class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-settings class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-share class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-shopping-bag class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-shopping-cart class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-square-check class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-square-rotated class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-star class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-sun class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-tag class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-thumb-up class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-trash class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-trash-lines class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-trending-up class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-twitter class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-user class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-user-plus class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-users class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-users-group class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-video class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-wheel class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-x class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-x-circle class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-zip-file class="h-6 w-6" />
                    </div>
                </div>

                <div class="mb-5 inline-block rounded bg-[#009688]/[.26] px-2 py-1 text-base text-[#009688]">Bold Duotone</div>
                <div class="mb-5 flex flex-wrap items-center gap-10">
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-apps class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-authentication class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-calendar class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-charts class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-chat class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-components class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-contacts class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-dashboard class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-datatables class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-documentation class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-drag-and-drop class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-elements class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-font-icons class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-forms class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-invoice class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-mailbox class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-more class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-notes class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-pages class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-scrumboard class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-tables class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-todo class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-users class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-menu-widgets class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-airplay [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-box [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-desktop [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-dollar-sign-circle [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-info-circle [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-layout [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-lock-dots [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-mail [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-message-dots [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-minus-circle [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-pencil [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-phone-call [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-play-circle [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-router [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-twitter [fill]="true" class="h-6 w-6" />
                    </div>
                    <div class="grid h-14 w-14 place-content-center rounded-md border border-white-dark/20 dark:border-[#191e3a]">
                        <icon-user [fill]="true" class="h-6 w-6" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
