import { Injectable, NgZone } from '@angular/core';
import { fromEvent, merge, Observable, Subscription, timer } from 'rxjs';
import { debounceTime, mapTo, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IdleService {
    private userActivity$: Observable<any>;
    private timer$: Observable<any> | any;
    private userActivitySubscription: Subscription;
    private idleSubscription: Subscription | any;
    private lastActivityTimestamp: number = Date.now();

    constructor(private ngZone: NgZone) {
        this.userActivity$ = merge(
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'scroll'),
            fromEvent(window, 'keydown')
        );

        this.userActivitySubscription = this.userActivity$.subscribe(() => this.updateLastActivityTimestamp());
    }

    startWatching() {
        this.ngZone.runOutsideAngular(() => {
            this.idleSubscription = this.userActivity$
                .pipe(
                    debounceTime(900000), // 5 minutes
                    switchMap(() => timer(0))
                )
                .subscribe(() => this.onIdle());
        });
    }

    stopWatching() {
        if (this.userActivitySubscription) {
            this.userActivitySubscription.unsubscribe();
        }
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
    }

    private updateLastActivityTimestamp() {
        this.lastActivityTimestamp = Date.now();
    }

    private onIdle() {
        // Handle idle state, for example, trigger logout
    }

    isUserActive(): boolean {
        const currentTime = Date.now();
        const fifteenMinutesInMs = 15 * 60 * 1000;
        return (currentTime - this.lastActivityTimestamp) < fifteenMinutesInMs;
    }
}
