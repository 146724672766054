// role.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const allowedRoles = next.data['allowedRoles'];
    const userRole = this.authService.getUserRole(); // Assuming you have a method to get the user's role

    if (allowedRoles.includes(userRole)) {
      return true; // Allow access if the user's role is in the allowed roles
    } else {
      // Redirect to unauthorized page or show an error message
      this.router.navigate(['/pages/error403']);
      return false; // Prevent access
    }
  }
}
