import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
interface MenuItem {
    label: string;
    routerLink?: string;
    icon?: string;
    children?: MenuItem[];
    disable?:boolean;
}
@Injectable({
    providedIn: 'root'
})
export class MenuService {
    // Define your menu items here or fetch them from an API
    defaultMenus: any = [
        {
            label: 'Dashboard',
            routerLink: '/',
            icon: 'menu-dashboard',
            children: [
                { label: 'Sales', routerLink: '/dashboard' },
                { label: 'Analytics', routerLink: '/analytics' },
                { label: 'Finance', routerLink: '/finance' },
                { label: 'Crypto', routerLink: '/crypto' }
            ]
        },
        {
            label: 'Apps',
            icon: 'menu-apps',
            children: [
                { label: 'Chat', routerLink: '/apps/chat' },
                { label: 'Mailbox', routerLink: '/apps/mailbox' },
                { label: 'Todo List', routerLink: '/apps/todolist' },
                { label: 'Notes', routerLink: '/apps/notes' },
                { label: 'Scrumboard', routerLink: '/apps/scrumboard' },
                { label: 'Contacts', routerLink: '/apps/contacts' },
                { label: 'List', routerLink: '/apps/invoice/list' },
                { label: 'Preview', routerLink: '/apps/invoice/preview' },
                { label: 'Add', routerLink: '/apps/invoice/add' },
                { label: 'Edit', routerLink: '/apps/invoice/edit' },
                { label: 'Calendar', routerLink: '/apps/calendar' }
            ]
        },
        {
            label: 'Components',
            icon: 'menu-components',
            children: [
                { label: 'Tabs', routerLink: '/components/tabs' },
                { label: 'Accordions', routerLink: '/components/accordions' },
                { label: 'Modals', routerLink: '/components/modals' },
                { label: 'Cards', routerLink: '/components/cards' },
                { label: 'Carousel', routerLink: '/components/carousel' },
                { label: 'Countdown', routerLink: '/components/countdown' },
                { label: 'Counter', routerLink: '/components/counter' },
                { label: 'Sweet Alerts', routerLink: '/components/sweetalert' },
                { label: 'Timeline', routerLink: '/components/timeline' },
                { label: 'Notifications', routerLink: '/components/notifications' },
                { label: 'Media Object', routerLink: '/components/media-object' },
                { label: 'List Group', routerLink: '/components/list-group' },
                { label: 'Pricing Tables', routerLink: '/components/pricing-table' },
                { label: 'Lightbox', routerLink: '/components/lightbox' }
            ]
        },
        {
            label: 'Elements',
            icon: 'menu-elements',
            children: [
                { label: 'Alerts', routerLink: '/elements/alerts' },
                { label: 'Avatar', routerLink: '/elements/avatar' },
                { label: 'Badges', routerLink: '/elements/badges' },
                { label: 'Breadcrumbs', routerLink: '/elements/breadcrumbs' },
                { label: 'Buttons', routerLink: '/elements/buttons' },
                { label: 'Button Groups', routerLink: '/elements/buttons-group' },
                { label: 'Color Library', routerLink: '/elements/color-library' },
                { label: 'Dropdown', routerLink: '/elements/dropdown' },
                { label: 'Infobox', routerLink: '/elements/infobox' },
                { label: 'Jumbotron', routerLink: '/elements/jumbotron' },
                { label: 'Loader', routerLink: '/elements/loader' },
                { label: 'Pagination', routerLink: '/elements/pagination' },
                { label: 'Popovers', routerLink: '/elements/popovers' },
                { label: 'Progress Bar', routerLink: '/elements/progress-bar' },
                { label: 'Search', routerLink: '/elements/search' },
                { label: 'Tooltips', routerLink: '/elements/tooltips' },
                { label: 'Treeview', routerLink: '/elements/treeview' },
                { label: 'Typography', routerLink: '/elements/typography' },
                { label: 'Charts', routerLink: '/charts' },
                { label: 'Widgets', routerLink: '/widgets' },
                { label: 'Font Icons', routerLink: '/font-icons' },
                { label: 'Drag and Drop', routerLink: '/dragndrop' }
            ]
        },
        {
            label: 'Tables and Datatables',
            icon: 'menu-tables',
            children: [
                { label: 'Tables', routerLink: '/tables' },
                { label: 'Basic', routerLink: '/datatables/basic' },
                { label: 'Advanced', routerLink: '/datatables/advanced' },
                { label: 'Skin', routerLink: '/datatables/skin' },
                { label: 'Order Sorting', routerLink: '/datatables/order-sorting' },
                { label: 'Columns Filter', routerLink: '/datatables/columns-filter' },
                { label: 'Multi Column', routerLink: '/datatables/multi-column' },
                { label: 'Multiple Tables', routerLink: '/datatables/multiple-tables' },
                { label: 'Alt Pagination', routerLink: '/datatables/alt-pagination' },
                { label: 'Checkbox', routerLink: '/datatables/checkbox' },
                { label: 'Range Search', routerLink: '/datatables/range-search' },
                { label: 'Export', routerLink: '/datatables/export' },
                { label: 'Sticky Header', routerLink: '/datatables/sticky-header' },
                { label: 'Clone Header', routerLink: '/datatables/clone-header' },
                { label: 'Column Chooser', routerLink: '/datatables/column-chooser' }
            ]
        },
        {
            label: 'Forms',
            icon: 'menu-forms',
            isOpen: false,
            children: [
                { label: 'Basic', routerLink: '/forms/basic' },
                { label: 'Input Group', routerLink: '/forms/input-group' },
                { label: 'Layouts', routerLink: '/forms/layouts' },
                { label: 'Validation', routerLink: '/forms/validation' },
                { label: 'Input Mask', routerLink: '/forms/input-mask' },
                { label: 'Select2', routerLink: '/forms/select2' },
                { label: 'Touchspin', routerLink: '/forms/touchspin' },
                { label: 'Checkbox and Radio', routerLink: '/forms/checkbox-radio' },
                { label: 'Switches', routerLink: '/forms/switches' },
                { label: 'Wizards', routerLink: '/forms/wizards' },
                { label: 'File Upload', routerLink: '/forms/file-upload' },
                { label: 'Quill Editor', routerLink: '/forms/quill-editor' },
                { label: 'Markdown Editor', routerLink: '/forms/markdown-editor' },
                { label: 'Date and Range Picker', routerLink: '/forms/date-picker' },
                { label: 'Clipboard', routerLink: '/forms/clipboard' }
            ]
        },
        {
            label: 'Users',
            icon: 'menu-users',
            isOpen: false,
            children: [
                { label: 'Profile', routerLink: '/users/profile' },
                { label: 'Account Settings', routerLink: '/users/user-account-settings' }
            ]
        },
        {
            label: 'Pages',
            icon: 'menu-pages',
            isOpen: false,
            children: [
                { label: 'Knowledge Base', routerLink: '/pages/knowledge-base' },
                { label: 'Contact Us Boxed', routerLink: '/pages/contact-us-boxed' },
                { label: 'Contact Us Cover', routerLink: '/pages/contact-us-cover' },
                { label: 'FAQ', routerLink: '/pages/faq' },
                { label: 'Coming Soon Boxed', routerLink: '/pages/coming-soon-boxed' },
                { label: 'Coming Soon Cover', routerLink: '/pages/coming-soon-cover' },
                { label: '403', routerLink: '/pages/error403' },
                { label: '404', routerLink: '/pages/error404' },
                { label: '500', routerLink: '/pages/error500' },
                { label: '503', routerLink: '/pages/error503' },
                { label: 'Maintenance', routerLink: '/pages/maintenance' }
            ]
        },
        {
            label: 'Authentication',
            isOpen: false,
            icon: 'menu-authentication',
            children: [
                { label: 'Login Boxed', routerLink: '/login' },
                { label: 'Register Boxed', routerLink: '/signup' },
                { label: 'Unlock Boxed', routerLink: '/auth/boxed-lockscreen' },
                { label: 'Recover ID Boxed', routerLink: '/auth/boxed-password-reset' }
            ]
        }
    ];
    private adminMenus: any = [
        {
            label: 'Dashboard',
            routerLink: '/admin/dashboard',
            icon: 'menu-dashboard'
        },
        {
            label: 'Companies',
            routerLink: '/admin/companies',
            icon: 'menu-users'
        },
        {
            label: 'Master Data',
            isOpen: false,
            icon: 'settings',
            children: [
                {
                    label: 'Subscription Plans',
                    routerLink: '/admin/subscription-plan',
                },
                {
                    label: 'Standards',
                    routerLink: '/admin/standards',
                },
                {
                    label: 'Appendix',
                    routerLink: '/admin/appendix',
                }
            ]
        },
        {
            label: 'Policies',
            isOpen: false,
            icon: 'menu-authentication',
            children: [
                {
                    label: 'Quality',
                    routerLink: '/admin/policy/Quality',
                },
                {
                    label: 'Environmental',
                    routerLink: '/admin/policy/Environmental',
                },
                {
                    label: 'Health & Safety',
                    routerLink: '/admin/policy/Health & Safety',
                },
                {
                    label: 'R2',
                    routerLink: '/admin/policy/R2',
                },
                {
                    label: 'RIOS',
                    routerLink: '/admin/policy/RIOS',
                }
            ]
        }
    ];
    private companyMenus: any = [
        {
            label: 'Dashboard',
            routerLink: '/company/dashboard',
            icon: 'menu-dashboard'
        }
    ];
    currentModule: string = '';
    originalMenuItems = [];
    convertedMenuItems: MenuItem[] = [];

    constructor(private route: ActivatedRoute, private authService: AuthService) {
        this.setCurrentModule();
    }
    menuMapper = [
        { title: 'Facility Management', path: '/company/facility-management', icon: 'menu-apps', default: true },
        // { title: 'Events', path: '/company/event', icon: 'menu-calendar' },
        { title: 'System Calendar', path: '/company/system-calendar', icon: 'menu-calendar' },
        // { title: 'Requirements', path: '/company/system-calendar/requirements' },
        { title: 'Scope', path: '/company/scope', icon: 'menu-widgets' },
        // { title: 'scope', path: '/company/scope' },
        { title: 'IOP', path: '/company/scope/iop' },
        { title: 'Applicable Appendices', path: '/company/scope/applicable-appendices' },
        { title: 'Exclusions', path: '/company/scope/exclusions' },
        { title: 'Policies', path: '/company/policies', icon: 'menu-authentication' },
        { title: 'Quality', path: '/company/policy/Quality' },
        { title: 'Environmental', path: '/company/policy/Environmental' },
        { title: 'Health & Safety', path: '/company/policy/Health & Safety' },
        { title: 'R2', path: '/company/policy/R2' },
        { title: 'RIOS', path: '/company/policy/RIOS' },
        { title: 'Personnel', path: '/company/personnel', icon: 'notes' },
        { title: 'Org Chart', path: '/company/personnel/org-chart' },
        { title: 'Job Description', path: '/company/personnel/job-description' },
        { title: 'Training Matrix', path: '/company/personnel/training-matrix' },
        { title: 'Educations', path: '/company/personnel/educations' },
        { title: 'Positions', path: '/company/personnel/positions' },
        { title: 'Trainings', path: '/company/personnel/trainings' },
        { title: 'Risk Management', path: '/company/risk-management', icon: 'menu-charts' },
        { title: 'COTO', path: '/company/risk-management/coto' },
        { title: 'Interested Parties', path: '/company/risk-management/interested-parties' },
        { title: 'Internal Issues', path: '/company/risk-management/internal-issues' },
        { title: 'External Issues', path: '/company/risk-management/external-issues' },
        { title: 'Interested Parties', path: '/company/risk-management/interested-parties' },
        { title: 'Quality Risks', path: '/company/risk-management/quality-risks' },
        { title: 'H&S Hazards', path: '/company/risk-management/h-and-s-hazards' },
        { title: 'Environmental Aspects', path: '/company/risk-management/environmental-aspects' },
        { title: 'Significant Risks', path: '/company/risk-management/significant-risks' },
        { title: 'Compliance', path: '/company/ech', icon: 'sun' },
        { title: 'Compliance Obligation', path: '/company/ech/compliance-obligation' },
        { title: 'Import/Export', path: '/company/ech/import-export' },
        { title: 'Compliance Audit', path: '/company/ech/compliance-audit' },
        { title: 'Hazard Communication', path: '/company/hazard-communication', icon: 'cpu-bolt' },
        { title: 'Chemical List', path: '/company/hazard-communication/chemical-list' },
        { title: 'Hazard Communication Evaluation', path: '/company/hazard-communication/hazard-communication-evaluation' },
        { title: 'Support Management', path: '/company/support-management', icon: 'user-plus' },
        { title: 'Contractors', path: '/company/support-management/contractors' },
        { title: 'Vendors', path: '/company/support-management/vendors' },
        { title: 'Downstream Vendors', path: '/company/support-management/downstream-vendors' },
        { title: 'Operations', path: '/company/operations', icon: 'wheel' },
        { title: 'Inbound Report', path: '/company/operations/inbound-summary' },
        { title: 'Inventory Report', path: '/company/operations/inventory-report' },
        { title: 'Container Log', path: '/company/operations/inventory-logs' },
        { title: 'Asset Log', path: '/company/operations/asset-log' },
        { title: 'Outbound Summary', path: '/company/operations/outbound-summary' },
        { title: 'QC Report', path: '/company/operations/qc-report' },
        { title: 'QC Log', path: '/company/operations/qc-logs' },
        { title: 'RMAs', path: '/company/operations/rmas' },
        { title: 'Mass Balance Summary', path: '/company/operations/mass-balance-summary' },
        { title: 'Packing List', path: '/company/operations/packing-list' },
        { title: 'Recalls Log', path: '/company/operations/recalls-logs' },
        { title: 'Monitoring', path: '/company/monitoring', icon: 'desktop' },
        { title: 'Facility Inspection', path: '/company/monitoring/facility-inspection' },
        { title: 'Internal Audit', path: '/company/monitoring/internal-audit' },
        { title: 'Equipment List', path: '/company/monitoring/equipment-master' },
        { title: 'Calibration Schedule', path: '/company/monitoring/calibration-schedule' },
        { title: 'Maintenance Schedule', path: '/company/monitoring/maintenance-schedule' },
        { title: 'Improvement', path: '/company/improvement', icon: 'layout' },
        { title: 'Improvement Log', path: '/company/improvement/log', icon: 'layout' },
        { title: 'Communications', path: '/company/improvement/communications' },
        { title: 'Management of Change', path: '/company/improvement/management-of-change' },
        { title: 'Objectives', path: '/company/improvement/objectives' },
        { title: 'Corrective Actions', path: '/company/improvement/corrective-actions' },
        { title: 'Management Review', path: '/company/management-review', icon: 'eye' },
        { title: 'Document Control', path: '/company/document-control', icon: 'code-file' }
    ];


    getPathByTitle(title: string, _default = '/company'): string | undefined {
        const mapping = this.menuMapper.find(item => item.title === title);
        return mapping ? mapping.path : _default;
    }
    getIconByTitle(title: string, _default: any = undefined): string | undefined {
        const mapping = this.menuMapper.find(item => item.title === title);
        return mapping ? mapping.icon : _default;
    }
    getMenuItemsByModule(moduleName: string) {
        this.originalMenuItems = this.getUserMenus();
        this.originalMenuItems = this.originalMenuItems ? this.originalMenuItems : [];
        this.convertedMenuItems = [];
        switch (moduleName) {
            case 'company':
            case 'admin':
                if (moduleName == 'company') {
                    this.convertedMenuItems = [...this.companyMenus];
                } else if (moduleName == 'admin') {
                    this.convertedMenuItems = [...this.adminMenus];
                }
                this.convertMenuItems();
                return this.convertedMenuItems;
            default:
                return this.defaultMenus;
        }
    }
    setCurrentModule(): void {
        // Traverse through the route tree to find the first activated route
        let route = this.route.snapshot;
        while (route.firstChild) {
            route = route.firstChild;
        }
        // Extract the data object which contains the module name
        this.currentModule = route.data['module'] || '';
    }

    getUserMenus() {
        return this.authService.getUserMenus()
    }
    /**
     * Convert db menus in our structure
     */
    convertMenuItems() {
        const menuMap = new Map<number, MenuItem>();

        // Iterate through the original menu items to create a map with parent menu IDs
        this.originalMenuItems.forEach((item: any) => {
            const menuItem: MenuItem | any = {
                label: item.name,
                children: [],
                icon: this.getIconByTitle(item.name, 'menu-dashboard'),
                routerLink: this.getPathByTitle(item.name, item.name),
                disabled: item.permission == 'None' ? true : true
            };
            if (item.parentMenuId === 0) {
                menuMap.set(item.id, menuItem);
            } else {
                const parentItem = menuMap.get(item.parentMenuId);
                if (parentItem) {
                    parentItem.children?.push(menuItem);
                }
            }
        });

        // Iterate through the menu map to get top-level menu items
        menuMap.forEach((value, key) => {
            if (menuMap.get(key)?.label !== 'All') { // Skip 'All' menu items
                this.convertedMenuItems.push(value);
            }
        });
    }
    getMenuRole(routePath:any){
        let manipulator:any = {
            "policy" : 'policies',
            "ehs" : "compliance"
        }
        if(manipulator[routePath] !== undefined){
            routePath = manipulator[routePath];
        }
        this.originalMenuItems = this.getUserMenus();
        this.originalMenuItems = this.originalMenuItems ? this.originalMenuItems : [];
        let title = this.getTitleByPath(`/company/${routePath}`);
        let menu:any = this.originalMenuItems.find((item:any)=>item.name == title);
        return menu?.permission;
    }
    getTitleByPath(routePath:any): string | undefined {
        const mapping = this.menuMapper.find(item => item.path === routePath);
        return mapping ? mapping.title : '';
    }
}
