<div>
    <ul class="mb-6 flex space-x-2 rtl:space-x-reverse">
        <li>
            <a href="javascript:;" class="text-primary hover:underline">Dashboard</a>
        </li>
        <li class="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
            <span>Charts</span>
        </li>
    </ul>
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div class="panel flex items-center overflow-x-auto whitespace-nowrap p-3 text-primary lg:col-span-2">
            <div class="rounded-full bg-primary p-1.5 text-white ring-2 ring-primary/30 ltr:mr-3 rtl:ml-3">
                <icon-bell />
            </div>
            <span class="ltr:mr-3 rtl:ml-3">Documentation: </span>
            <a href="https://www.npmjs.com/package/ng-apexcharts" target="_blank" class="block hover:underline">https://www.npmjs.com/package/ng-apexcharts</a>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white-light">Simple Line</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code1')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="lineChart.series"
                    [chart]="lineChart.chart"
                    [colors]="lineChart.colors"
                    [tooltip]="lineChart.tooltip"
                    [stroke]="lineChart.stroke"
                    [xaxis]="lineChart.xaxis"
                    [yaxis]="lineChart.yaxis"
                    [grid]="lineChart.grid"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code1')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;lineChart.series&quot;
    [chart]=&quot;lineChart.chart&quot;
    [colors]=&quot;lineChart.colors&quot;
    [tooltip]=&quot;lineChart.tooltip&quot;
    [stroke]=&quot;lineChart.stroke&quot;
    [xaxis]=&quot;lineChart.xaxis&quot;
    [yaxis]=&quot;lineChart.yaxis&quot;
    [grid]=&quot;lineChart.grid&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
lineChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.lineChart = {
        series: [
            {
                name: 'Sales',
                data: [45, 55, 75, 25, 45, 110],
            },
        ],
        chart: {
            height: 300,
            type: 'line',
            toolbar: false,
        },
        colors: ['#4361ee'],
        tooltip: {
            marker: false,
            y: {
                formatter(number: string) {
                    return '$' + number;
                },
            },
            theme: isDark ? 'dark' : 'light',
        },
        stroke: {
            width: 2,
            curve: 'smooth',
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June'],
            axisBorder: {
                color: isDark ? '#191e3a' : '#e0e6ed',
            },
        },
        yaxis: {
            opposite: isRtl ? true : false,
            labels: {
                offsetX: isRtl ? -20 : 0,
            },
        },
        grid: {
            borderColor: isDark ? '#191e3a' : '#e0e6ed',
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Simple Area</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code2')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="areaChart.series"
                    [chart]="areaChart.chart"
                    [colors]="areaChart.colors"
                    [dataLabels]="areaChart.dataLabels"
                    [stroke]="areaChart.stroke"
                    [xaxis]="areaChart.xaxis"
                    [yaxis]="areaChart.yaxis"
                    [labels]="areaChart.labels"
                    [legend]="areaChart.legend"
                    [grid]="areaChart.grid"
                    [tooltip]="areaChart.tooltip"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code2')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;areaChart.series&quot;
    [chart]=&quot;areaChart.chart&quot;
    [colors]=&quot;areaChart.colors&quot;
    [dataLabels]=&quot;areaChart.dataLabels&quot;
    [stroke]=&quot;areaChart.stroke&quot;
    [xaxis]=&quot;areaChart.xaxis&quot;
    [yaxis]=&quot;areaChart.yaxis&quot;
    [labels]=&quot;areaChart.labels&quot;
    [legend]=&quot;areaChart.legend&quot;
    [grid]=&quot;areaChart.grid&quot;
    [tooltip]=&quot;areaChart.tooltip&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
areaChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.areaChart = {
        series: [
            {
                name: 'Income',
                data: [16800, 16800, 15500, 17800, 15500, 17000, 19000, 16000, 15000, 17000, 14000, 17000],
            },
        ],
        chart: {
            type: 'area',
            height: 300,
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#805dca'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 2,
            curve: 'smooth',
        },
        xaxis: {
            axisBorder: {
                color: isDark ? '#191e3a' : '#e0e6ed',
            },
        },
        yaxis: {
            opposite: isRtl ? true : false,
            labels: {
                offsetX: isRtl ? -40 : 0,
            },
        },
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        legend: {
            horizontalAlign: 'left',
        },
        grid: {
            borderColor: isDark ? '#191e3a' : '#e0e6ed',
        },
        tooltip: {
            theme: isDark ? 'dark' : 'light',
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Simple Column</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code3')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="columnChart.series"
                    [chart]="columnChart.chart"
                    [colors]="columnChart.colors"
                    [dataLabels]="columnChart.dataLabels"
                    [stroke]="columnChart.stroke"
                    [plotOptions]="columnChart.plotOptions"
                    [grid]="columnChart.grid"
                    [xaxis]="columnChart.xaxis"
                    [yaxis]="columnChart.yaxis"
                    [tooltip]="columnChart.tooltip"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code3')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;columnChart.series&quot;
    [chart]=&quot;columnChart.chart&quot;
    [colors]=&quot;columnChart.colors&quot;
    [dataLabels]=&quot;columnChart.dataLabels&quot;
    [stroke]=&quot;columnChart.stroke&quot;
    [plotOptions]=&quot;columnChart.plotOptions&quot;
    [grid]=&quot;columnChart.grid&quot;
    [xaxis]=&quot;columnChart.xaxis&quot;
    [yaxis]=&quot;columnChart.yaxis&quot;
    [tooltip]=&quot;columnChart.tooltip&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
columnChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;

    this.columnChart = {
        series: [
            {
                name: 'Net Profit',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
            },
            {
                name: 'Revenue',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
            },
        ],
        chart: {
            height: 300,
            type: 'bar',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#805dca', '#e7515a'],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
            },
        },
        grid: {
            borderColor: isDark ? '#191e3a' : '#e0e6ed',
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            axisBorder: {
                color: isDark ? '#191e3a' : '#e0e6ed',
            },
        },
        yaxis: {
            opposite: isRtl ? true : false,
            labels: {
                offsetX: isRtl ? -10 : 0,
            },
        },
        tooltip: {
            theme: isDark ? 'dark' : 'light',
            y: {
                formatter: function (val: any) {
                    return val;
                },
            },
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Simple Column Stacked</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code4')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="simpleColumnStacked.series"
                    [chart]="simpleColumnStacked.chart"
                    [colors]="simpleColumnStacked.colors"
                    [plotOptions]="simpleColumnStacked.plotOptions"
                    [xaxis]="simpleColumnStacked.xaxis"
                    [yaxis]="simpleColumnStacked.yaxis"
                    [grid]="simpleColumnStacked.grid"
                    [legend]="simpleColumnStacked.legend"
                    [tooltip]="simpleColumnStacked.tooltip"
                    [fill]="simpleColumnStacked.fill"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code4')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;simpleColumnStacked.series&quot;
    [chart]=&quot;simpleColumnStacked.chart&quot;
    [colors]=&quot;simpleColumnStacked.colors&quot;
    [plotOptions]=&quot;simpleColumnStacked.plotOptions&quot;
    [xaxis]=&quot;simpleColumnStacked.xaxis&quot;
    [yaxis]=&quot;simpleColumnStacked.yaxis&quot;
    [grid]=&quot;simpleColumnStacked.grid&quot;
    [legend]=&quot;simpleColumnStacked.legend&quot;
    [tooltip]=&quot;simpleColumnStacked.tooltip&quot;
    [fill]=&quot;simpleColumnStacked.fill&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
simpleColumnStacked: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;

    this.simpleColumnStacked = {
        series: [
            {
                name: 'PRODUCT A',
                data: [44, 55, 41, 67, 22, 43],
            },
            {
                name: 'PRODUCT B',
                data: [13, 23, 20, 8, 13, 27],
            },
        ],
        chart: {
            height: 300,
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#2196f3', '#3b3f5c'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 5,
                    },
                },
            },
        ],
        plotOptions: {
            bar: {
                horizontal: false,
            },
        },
        xaxis: {
            type: 'datetime',
            categories: ['01/01/2011 GMT', '01/02/2011 GMT', '01/03/2011 GMT', '01/04/2011 GMT', '01/05/2011 GMT', '01/06/2011 GMT'],
            axisBorder: {
                color: isDark ? '#191e3a' : '#e0e6ed',
            },
        },
        yaxis: {
            opposite: isRtl ? true : false,
            labels: {
                offsetX: isRtl ? -20 : 0,
            },
        },
        grid: {
            borderColor: isDark ? '#191e3a' : '#e0e6ed',
        },
        legend: {
            position: 'right',
            offsetY: 40,
        },
        tooltip: {
            theme: isDark ? 'dark' : 'light',
        },
        fill: {
            opacity: 0.8,
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Simple Bar</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code5')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="barChart.series"
                    [chart]="barChart.chart"
                    [dataLabels]="barChart.dataLabels"
                    [stroke]="barChart.stroke"
                    [colors]="barChart.colors"
                    [xaxis]="barChart.xaxis"
                    [yaxis]="barChart.yaxis"
                    [grid]="barChart.grid"
                    [plotOptions]="barChart.plotOptions"
                    [fill]="barChart.fill"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code5')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;barChart.series&quot;
    [chart]=&quot;barChart.chart&quot;
    [dataLabels]=&quot;barChart.dataLabels&quot;
    [stroke]=&quot;barChart.stroke&quot;
    [colors]=&quot;barChart.colors&quot;
    [xaxis]=&quot;barChart.xaxis&quot;
    [yaxis]=&quot;barChart.yaxis&quot;
    [grid]=&quot;barChart.grid&quot;
    [plotOptions]=&quot;barChart.plotOptions&quot;
    [fill]=&quot;barChart.fill&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
barChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.barChart = {
        series: [
            {
                name: 'Sales',
                data: [44, 55, 41, 67, 22, 43, 21, 70],
            },
        ],
        chart: {
            height: 300,
            type: 'bar',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 1,
        },
        colors: ['#4361ee'],
        xaxis: {
            categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
            axisBorder: {
                color: isDark ? '#191e3a' : '#e0e6ed',
            },
        },
        yaxis: {
            opposite: isRtl ? true : false,
            reversed: isRtl ? true : false,
        },
        grid: {
            borderColor: isDark ? '#191e3a' : '#e0e6ed',
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        fill: {
            opacity: 0.8,
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Mixed</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code6')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="mixedChart.series"
                    [chart]="mixedChart.chart"
                    [colors]="mixedChart.colors"
                    [stroke]="mixedChart.stroke"
                    [plotOptions]="mixedChart.plotOptions"
                    [fill]="mixedChart.fill"
                    [labels]="mixedChart.labels"
                    [markers]="mixedChart.markers"
                    [xaxis]="mixedChart.xaxis"
                    [yaxis]="mixedChart.yaxis"
                    [grid]="mixedChart.grid"
                    [tooltip]="mixedChart.tooltip"
                    [legend]="mixedChart.legend"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code6')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;mixedChart.series&quot;
    [chart]=&quot;mixedChart.chart&quot;
    [colors]=&quot;mixedChart.colors&quot;
    [stroke]=&quot;mixedChart.stroke&quot;
    [plotOptions]=&quot;mixedChart.plotOptions&quot;
    [fill]=&quot;mixedChart.fill&quot;
    [labels]=&quot;mixedChart.labels&quot;
    [markers]=&quot;mixedChart.markers&quot;
    [xaxis]=&quot;mixedChart.xaxis&quot;
    [yaxis]=&quot;mixedChart.yaxis&quot;
    [grid]=&quot;mixedChart.grid&quot;
    [tooltip]=&quot;mixedChart.tooltip&quot;
    [legend]=&quot;mixedChart.legend&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
mixedChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.mixedChart = {
        series: [
            {
                name: 'TEAM A',
                type: 'column',
                data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
            },
            {
                name: 'TEAM B',
                type: 'area',
                data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
            },
            {
                name: 'TEAM C',
                type: 'line',
                data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
            },
        ],
        chart: {
            height: 300,
            // stacked: false,
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#2196f3', '#00ab55', '#4361ee'],
        stroke: {
            width: [0, 2, 2],
            curve: 'smooth',
        },
        plotOptions: {
            bar: {
                columnWidth: '50%',
            },
        },
        fill: {
            opacity: [1, 0.25, 1],
        },

        labels: [
            '01/01/2022',
            '02/01/2022',
            '03/01/2022',
            '04/01/2022',
            '05/01/2022',
            '06/01/2022',
            '07/01/2022',
            '08/01/2022',
            '09/01/2022',
            '10/01/2022',
            '11/01/2022',
        ],
        markers: {
            size: 0,
        },
        xaxis: {
            type: 'datetime',
            axisBorder: {
                color: isDark ? '#191e3a' : '#e0e6ed',
            },
        },
        yaxis: {
            title: {
                text: 'Points',
            },
            min: 0,
            opposite: isRtl ? true : false,
            labels: {
                offsetX: isRtl ? -10 : 0,
            },
        },
        grid: {
            borderColor: isDark ? '#191e3a' : '#e0e6ed',
        },
        tooltip: {
            shared: true,
            intersect: false,
            theme: isDark ? 'dark' : 'light',
            y: {
                formatter: (y: number) =&gt; {
                    if (typeof y !== 'undefined') {
                        return y.toFixed(0) + ' points';
                    }
                    return y;
                },
            },
        },
        legend: {
            itemMargin: {
                horizontal: 4,
                vertical: 8,
            },
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Basic Radar</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code7')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="radarChart.series"
                    [chart]="radarChart.chart"
                    [colors]="radarChart.colors"
                    [xaxis]="radarChart.xaxis"
                    [plotOptions]="radarChart.plotOptions"
                    [tooltip]="radarChart.tooltip"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code7')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;radarChart.series&quot;
    [chart]=&quot;radarChart.chart&quot;
    [colors]=&quot;radarChart.colors&quot;
    [xaxis]=&quot;radarChart.xaxis&quot;
    [plotOptions]=&quot;radarChart.plotOptions&quot;
    [tooltip]=&quot;radarChart.tooltip&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
radarChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.radarChart = {
        series: [
            {
                name: 'Series 1',
                data: [80, 50, 30, 40, 100, 20],
            },
        ],
        chart: {
            height: 300,
            type: 'radar',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#4361ee'],
        xaxis: {
            categories: ['January', 'February', 'March', 'April', 'May', 'June'],
        },
        plotOptions: {
            radar: {
                polygons: {
                    strokeColors: isDark ? '#191e3a' : '#e0e6ed',
                    connectorColors: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
        },
        tooltip: {
            theme: isDark ? 'dark' : 'light',
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Basic Pie</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code8')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="pieChart.series"
                    [chart]="pieChart.chart"
                    [labels]="pieChart.labels"
                    [colors]="pieChart.colors"
                    [responsive]="pieChart.responsive"
                    [stroke]="pieChart.stroke"
                    [legend]="pieChart.legend"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code8')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;pieChart.series&quot;
    [chart]=&quot;pieChart.chart&quot;
    [labels]=&quot;pieChart.labels&quot;
    [colors]=&quot;pieChart.colors&quot;
    [responsive]=&quot;pieChart.responsive&quot;
    [stroke]=&quot;pieChart.stroke&quot;
    [legend]=&quot;pieChart.legend&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
pieChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.pieChart = {
        series: [44, 55, 13, 43, 22],
        chart: {
            height: 300,
            type: 'pie',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        colors: ['#4361ee', '#805dca', '#00ab55', '#e7515a', '#e2a03f'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
        stroke: {
            show: false,
        },
        legend: {
            position: 'bottom',
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Basic Donut</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code9')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="donutChart.series"
                    [chart]="donutChart.chart"
                    [stroke]="donutChart.stroke"
                    [labels]="donutChart.labels"
                    [colors]="donutChart.colors"
                    [legend]="donutChart.legend"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code9')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;donutChart.series&quot;
    [chart]=&quot;donutChart.chart&quot;
    [stroke]=&quot;donutChart.stroke&quot;
    [labels]=&quot;donutChart.labels&quot;
    [colors]=&quot;donutChart.colors&quot;
    [legend]=&quot;donutChart.legend&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
donutChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.donutChart = {
        series: [44, 55, 13],
        chart: {
            height: 300,
            type: 'donut',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        stroke: {
            show: false,
        },
        labels: ['Team A', 'Team B', 'Team C'],
        colors: ['#4361ee', '#805dca', '#e2a03f'],
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
        legend: {
            position: 'bottom',
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Basic Polar Area</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code10')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="polarAreaChart.series"
                    [chart]="polarAreaChart.chart"
                    [colors]="polarAreaChart.colors"
                    [stroke]="polarAreaChart.stroke"
                    [responsive]="polarAreaChart.responsive"
                    [plotOptions]="polarAreaChart.plotOptions"
                    [legend]="polarAreaChart.legend"
                    [fill]="polarAreaChart.fill"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code10')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;polarAreaChart.series&quot;
    [chart]=&quot;polarAreaChart.chart&quot;
    [colors]=&quot;polarAreaChart.colors&quot;
    [stroke]=&quot;polarAreaChart.stroke&quot;
    [responsive]=&quot;polarAreaChart.responsive&quot;
    [plotOptions]=&quot;polarAreaChart.plotOptions&quot;
    [legend]=&quot;polarAreaChart.legend&quot;
    [fill]=&quot;polarAreaChart.fill&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
polarAreaChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.polarAreaChart = {
        series: [14, 23, 21, 17, 15, 10, 12, 17, 21],
        chart: {
            height: 300,
            type: 'polarArea',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#4361ee', '#805dca', '#00ab55', '#e7515a', '#e2a03f', '#2196f3', '#3b3f5c'],
        stroke: {
            show: false,
        },
        responsive: [
            {
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                    },
                },
            },
        ],
        plotOptions: {
            polarArea: {
                rings: {
                    strokeColor: isDark ? '#191e3a' : '#e0e6ed',
                },
                spokes: {
                    connectorColors: isDark ? '#191e3a' : '#e0e6ed',
                },
            },
        },
        legend: {
            position: 'bottom',
        },
        fill: {
            opacity: 0.85,
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Radial Bar</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code11')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="radialBarChart.series"
                    [chart]="radialBarChart.chart"
                    [colors]="radialBarChart.colors"
                    [grid]="radialBarChart.grid"
                    [plotOptions]="radialBarChart.plotOptions"
                    [labels]="radialBarChart.labels"
                    [fill]="radialBarChart.fill"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code11')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;radialBarChart.series&quot;
    [chart]=&quot;radialBarChart.chart&quot;
    [colors]=&quot;radialBarChart.colors&quot;
    [grid]=&quot;radialBarChart.grid&quot;
    [plotOptions]=&quot;radialBarChart.plotOptions&quot;
    [labels]=&quot;radialBarChart.labels&quot;
    [fill]=&quot;radialBarChart.fill&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
radialBarChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.radialBarChart = {
        series: [44, 55, 41],
        chart: {
            height: 300,
            type: 'radialBar',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#4361ee', '#805dca', '#e2a03f'],
        grid: {
            borderColor: isDark ? '#191e3a' : '#e0e6ed',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '22px',
                    },
                    value: {
                        fontSize: '16px',
                    },
                    total: {
                        show: true,
                        label: 'Total',
                        formatter: function (w: any) {
                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                            return 249;
                        },
                    },
                },
            },
        },
        labels: ['Apples', 'Oranges', 'Bananas'],
        fill: {
            opacity: 0.85,
        },
    };
}"></code> </pre>
            </ng-container>
        </div>

        <div class="panel">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white">Bubble Chart</h5>
                <a class="font-semibold hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-600" href="javascript:;" (click)="toggleCode('code12')"
                    ><span class="flex items-center">
                        <icon-code class="me-2" />
                        Code
                    </span>
                </a>
            </div>
            <div class="mb-5 overflow-hidden">
                <apx-chart
                    [series]="bubbleChart.series"
                    [chart]="bubbleChart.chart"
                    [colors]="bubbleChart.colors"
                    [dataLabels]="bubbleChart.dataLabels"
                    [xaxis]="bubbleChart.xaxis"
                    [yaxis]="bubbleChart.yaxis"
                    [grid]="bubbleChart.grid"
                    [tooltip]="bubbleChart.tooltip"
                    [stroke]="bubbleChart.stroke"
                    [fill]="bubbleChart.fill"
                    class="rounded-lg bg-white dark:bg-black"
                ></apx-chart>
            </div>
            <ng-container *ngIf="codeArr.includes('code12')">
                <pre> <code [languages]="['xml','typescript']" highlight="&lt;apx-chart
    [series]=&quot;bubbleChart.series&quot;
    [chart]=&quot;bubbleChart.chart&quot;
    [colors]=&quot;bubbleChart.colors&quot;
    [dataLabels]=&quot;bubbleChart.dataLabels&quot;
    [xaxis]=&quot;bubbleChart.xaxis&quot;
    [yaxis]=&quot;bubbleChart.yaxis&quot;
    [grid]=&quot;bubbleChart.grid&quot;
    [tooltip]=&quot;bubbleChart.tooltip&quot;
    [stroke]=&quot;bubbleChart.stroke&quot;
    [fill]=&quot;bubbleChart.fill&quot;
    class=&quot;rounded-lg bg-white dark:bg-black&quot;
&gt;&lt;/apx-chart&gt;

&lt;!-- script --&gt;
bubbleChart: any;
ngOnInit() {
    const isDark = this.store.theme === 'dark' || this.store.isDarkMode ? true : false;
    const isRtl = this.store.rtlClass === 'rtl' ? true : false;
    this.bubbleChart = {
        series: [
            {
                name: 'Bubble1',
                data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                    min: 10,
                    max: 60,
                }),
            },
            {
                name: 'Bubble2',
                data: this.generateData(new Date('11 Feb 2017 GMT').getTime(), 20, {
                    min: 10,
                    max: 60,
                }),
            },
        ],
        chart: {
            height: 300,
            type: 'bubble',
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: ['#4361ee', '#00ab55'],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            tickAmount: 12,
            type: 'category',
            axisBorder: {
                color: isDark ? '#191e3a' : '#e0e6ed',
            },
        },
        yaxis: {
            max: 70,
            opposite: isRtl ? true : false,
            labels: {
                offsetX: isRtl ? -10 : 0,
            },
        },
        grid: {
            borderColor: isDark ? '#191e3a' : '#e0e6ed',
        },
        tooltip: {
            theme: isDark ? 'dark' : 'light',
        },
        stroke: {
            colors: isDark ? ['#191e3a'] : ['#e0e6ed'],
        },
        fill: {
            opacity: 0.85,
        },
    };
}"></code> </pre>
            </ng-container>
        </div>
    </div>
</div>
