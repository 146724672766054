import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from '../@core/services/menu.service';
import { slideDownUp } from '../shared/animations';
import { AuthService } from '../@core/services/auth.service';

@Component({
    moduleId: module.id,
    selector: 'sidebar',
    templateUrl: './sidebar.html',
    animations: [slideDownUp],
})
export class SidebarComponent {
    active = false;
    store: any;
    activeDropdown: string[] = [];
    parentDropdown: string = '';
    menuItems: any = [];
    companyLogo: any;
    currentModule: any;
    haveFacilities: any;

    constructor(private menuService: MenuService, public translate: TranslateService, public storeData: Store<any>, public router: Router,
        private authService: AuthService) {
        let facilityCount = 0; this.authService.getUserData('facilityCount')
        this.haveFacilities = facilityCount ? true : false;
        this.initStore();
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationEnd) {
                this.menuService.setCurrentModule();
                this.currentModule = this.menuService.currentModule;
                this.menuItems = this.menuService.getMenuItemsByModule(this.menuService.currentModule);
            }
        });
    }
    async initStore() {
        this.storeData
            .select((d) => d.index)
            .subscribe((d) => {
                this.store = d;
            });
    }

    ngOnInit() {
        this.setActiveDropdown();
        this.companyLogo = this.authService.getAttributeFromToken('CompanyLogo')
    }
    setActiveDropdown() {
        const selector = document.querySelector('.sidebar ul a[routerLink="' + window.location.pathname + '"]');
        if (selector) {
            selector.classList.add('active');
            const ul: any = selector.closest('ul.sub-menu');
            if (ul) {
                let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link') || [];
                if (ele.length) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            }
        }
    }

    toggleMobileMenu() {
        if (window.innerWidth < 1024) {
            this.storeData.dispatch({ type: 'toggleSidebar' });
        }
    }

    toggleAccordion(name: string, parent?: string) {
        if (this.activeDropdown.includes(name)) {
            this.activeDropdown = this.activeDropdown.filter((d) => d !== name);
        } else {
            this.activeDropdown.push(name);
        }
    }
    isDropdownActive(label: string): boolean {
        return this.activeDropdown.includes(label.toLowerCase());
    }
    showAlert(){
    }
}
