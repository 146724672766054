<div class="z-40 shadow-sm" [ngClass]="{ 'dark text-white-dark': store.semidark && store.menu === 'horizontal'}">
    <div class="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-[#0e1726]">
        <div class="horizontal-logo flex items-center justify-between ltr:mr-2 rtl:ml-2 lg:hidden">
            <a routerLink="/" class="main-logo flex shrink-0 items-center"
                *ngIf="currentModule!='company' || !companyLogo">
                <img class="inline w-8 ltr:-ml-1 rtl:-mr-1" src="/assets/images/logo.png" alt="" />
                <span
                    class="hidden align-middle text-2xl font-semibold transition-all duration-300 ltr:ml-1.5 rtl:mr-1.5 dark:text-white-light md:inline">
                    P3 LogiQ
                </span>
            </a>
            <a routerLink="/company" class="main-logo flex shrink-0 items-center"
                *ngIf="currentModule=='company' && companyLogo">
                <img class="inline w-20 ltr:-ml-1 rtl:-mr-1" [src]="companyLogo" alt="" />

            </a>

            <a href="javascript:;"
                class="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary ltr:ml-2 rtl:mr-2 dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden"
                (click)="storeData.dispatch({type: 'toggleSidebar'})">
                <icon-menu class="h-5 w-5" />
            </a>
        </div>
        <div class="hidden ltr:mr-2 rtl:ml-2 sm:block">

        </div>
        <div
            class="flex items-center space-x-1.5 ltr:ml-auto rtl:mr-auto rtl:space-x-reverse dark:text-[#d0d2d6] sm:flex-1 ltr:sm:ml-0 sm:rtl:mr-0 lg:space-x-2">
            <div class="sm:ltr:mr-auto sm:rtl:ml-auto">
            </div>
            <!-- <div>
                <a href="javascript:;" *ngIf="store.theme === 'light'"
                    class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                    (click)="storeData.dispatch({type: 'toggleTheme', payload:'dark'})">
                    <icon-sun />
                </a>
                <a href="javascript:;" *ngIf="store.theme === 'dark'"
                    class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                    (click)="storeData.dispatch({type: 'toggleTheme', payload:'system'})">
                    <icon-moon />
                </a>
                <a href="javascript:;" *ngIf="store.theme === 'system'"
                    class="flex items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                    (click)="storeData.dispatch({type: 'toggleTheme', payload:'light'})">
                    <icon-laptop />
                </a>
            </div>

            <div class="dropdown shrink-0">
                <div hlMenu>
                    <button type="button" hlMenuButton
                        class="block rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60">
                        <icon-mail-dot />
                    </button>
                    <ul *hlMenuItems @toggleAnimation
                        class="top-11 w-[300px] !py-0 text-xs text-dark ltr:-right-16 rtl:-left-16 dark:text-white-dark sm:w-[375px] sm:ltr:-right-2 sm:rtl:-left-2">
                        <li class="mb-5">
                            <div class="relative overflow-hidden rounded-t-md !p-5 text-white">
                                <div
                                    class="absolute inset-0 h-full w-full bg-[url('/assets/images/menu-heade.jpg')] bg-cover bg-center bg-no-repeat">
                                </div>
                                <h4 class="relative z-10 text-lg font-semibold">Messages</h4>
                            </div>
                        </li>
                        <li *ngFor="let msg of messages">
                            <div class="flex items-center px-5 py-3">
                                <div [innerHTML]="msg.image"></div>
                                <span class="px-3 dark:text-gray-500">
                                    <div class="text-sm font-semibold dark:text-white-light/90">{{msg.title}}</div>
                                    <div>{{msg.message}}</div>
                                </span>
                                <span
                                    class="whitespace-pre rounded bg-white-dark/20 px-1 font-semibold text-dark/60 ltr:ml-auto ltr:mr-2 rtl:ml-2 rtl:mr-auto dark:text-white-dark">{{msg.time}}</span>
                                <button type="button" class="text-neutral-300 hover:text-danger"
                                    (click)="$event.stopPropagation();removeMessage(msg.id)">
                                    <icon-x-circle />
                                </button>
                            </div>
                        </li>
                        <li *ngIf="messages.length"
                            class="mt-5 border-t border-white-light text-center dark:border-white/10">
                            <div class="group flex cursor-pointer items-center justify-center px-5 py-4 font-semibold text-primary dark:text-gray-400"
                                *hlMenuItem="let menuItem">
                                <span class="group-hover:underline ltr:mr-1 rtl:ml-1">VIEW ALL ACTIVITIES</span>

                                <icon-arrow-left
                                    class="transition duration-300 group-hover:translate-x-1 ltr:ml-1 rtl:mr-1" />
                            </div>
                        </li>
                        <li *ngIf="!messages.length" class="mb-5">
                            <div class="!grid min-h-[200px] place-content-center text-lg hover:!bg-transparent">
                                <div class="mx-auto mb-4 rounded-full text-primary ring-4 ring-primary/30">
                                    <icon-info-circle [fill]="true" class="h-10 w-10" />
                                </div>
                                No data available.
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="dropdown shrink-0">
                <div hlMenu>
                    <button type="button" hlMenuButton
                        class="relative block rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60">
                        <icon-bell-bing />

                        <span class="absolute top-0 flex h-3 w-3 ltr:right-0 rtl:left-0">
                            <span
                                class="absolute -top-[3px] inline-flex h-full w-full animate-ping rounded-full bg-success/50 opacity-75 ltr:-left-[3px] rtl:-right-[3px]"></span>
                            <span class="relative inline-flex h-[6px] w-[6px] rounded-full bg-success"></span>
                        </span>
                    </button>
                    <ul *hlMenuItems @toggleAnimation
                        class="top-11 w-[300px] divide-y !py-0 text-dark ltr:-right-2 rtl:-left-2 dark:divide-white/10 dark:text-white-dark sm:w-[350px]">
                        <li>
                            <div class="flex items-center justify-between px-4 py-2 font-semibold">
                                <h4 class="text-lg">Notification</h4>
                                <span *ngIf="notifications.length" class="badge bg-primary/80">{{notifications.length +
                                    'New'}}</span>
                            </div>
                        </li>
                        <li *ngFor="let notification of notifications" class="dark:text-white-light/90">
                            <div class="group flex items-center px-4 py-2">
                                <div class="grid place-content-center rounded">
                                    <div class="relative h-12 w-12">
                                        <img class="h-12 w-12 rounded-full object-cover"
                                            [src]="'/assets/images/' + notification.profile" alt="" />
                                        <span
                                            class="absolute bottom-0 right-[6px] block h-2 w-2 rounded-full bg-success"></span>
                                    </div>
                                </div>
                                <div class="flex flex-auto ltr:pl-3 rtl:pr-3">
                                    <div class="ltr:pr-3 rtl:pl-3">
                                        <h6 [innerHTML]="notification.message"></h6>
                                        <span
                                            class="block text-xs font-normal dark:text-gray-500">{{notification.time}}</span>
                                    </div>
                                    <button type="button"
                                        class="text-neutral-300 opacity-0 hover:text-danger group-hover:opacity-100 ltr:ml-auto rtl:mr-auto"
                                        (click)="$event.stopPropagation();removeNotification(notification.id)">
                                        <icon-x-circle />
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="notifications.length">
                            <div class="p-4">
                                <button type="button" class="btn btn-primary btn-small block w-full"
                                    *hlMenuItem="let menuItem">Read All Notifications</button>
                            </div>
                        </li>
                        <li *ngIf="!notifications.length">
                            <div class="!grid min-h-[200px] place-content-center text-lg hover:!bg-transparent">
                                <div class="mx-auto mb-4 rounded-full text-primary ring-4 ring-primary/30">
                                    <icon-info-circle [fill]="true" class="h-10 w-10" />
                                </div>
                                No data available.
                            </div>
                        </li>
                    </ul>
                </div>
            </div> -->

            <div class="dropdown shrink-0">
                <div hlMenu class="!block">
                    <button type="button" hlMenuButton
                        class="relative block rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60">
                        <icon-user></icon-user>
                    </button>
                    <ul *hlMenuItems @toggleAnimation
                        class="top-11 w-[230px] !py-0 font-semibold text-dark ltr:right-0 rtl:left-0 dark:text-white-dark dark:text-white-light/90">
                        <li>
                            <div class="flex items-center px-4 py-4">
                                <div class="flex-none">
                                    <icon-user></icon-user>
                                </div>
                                <div class="truncate ltr:pl-4 rtl:pr-4">
                                    <h4 class="text-base">
                                        {{userData.userName}}
                                    </h4>
                                    <a class="text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white"
                                        href="javascript:;">{{userData.userEmail}}</a>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="currentModule=='company'">
                            <a routerLink="/company/profile" *hlMenuItem="let menuItem"
                                class="dark:hover:text-white">
                                <icon-user class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                                Profile
                            </a>
                        </li>
                        <li class="border-t border-white-light dark:border-white-light/10">
                            <a (click)="signOut()" *hlMenuItem="let menuItem" class="!py-3 text-danger">
                                <icon-logout class="h-4.5 w-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2" />
                                Sign Out
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>