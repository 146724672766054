<!-- bootstrap.min css -->
<link rel="stylesheet" href="/assets/theme/plugins/bootstrap/bootstrap.min.css">
<!-- fontawesome css -->
<link rel="stylesheet" href="/assets/theme/plugins/fontawesome/css/all.css">
<!-- Icofont -->
<link rel="stylesheet" href="/assets/theme/plugins/icofont/icofont.css">

<!-- Main Stylesheet -->
<link rel="stylesheet" href="/assets/theme/css/style.css">

<!--Favicon-->
<link rel="icon" href="/assets/theme/images/Logo.png" type="image/x-icon">

<app-website-header></app-website-header>
<router-outlet></router-outlet>
<app-website-footer></app-website-footer>