import { IconLoaderComponent } from "@bhplugin/ng-datatable";
import { IconAirplayComponent } from "./icon-airplay";
import { IconArchiveComponent } from "./icon-archive";
import { IconArrowBackwardComponent } from "./icon-arrow-backward";
import { IconArrowForwardComponent } from "./icon-arrow-forward";
import { IconArrowLeftComponent } from "./icon-arrow-left";
import { IconArrowWaveLeftUpComponent } from "./icon-arrow-wave-left-up";
import { IconAtComponent } from "./icon-at";
import { IconAwardComponent } from "./icon-award";
import { IconBarChartComponent } from "./icon-bar-chart";
import { IconBellComponent } from "./icon-bell";
import { IconBellBingComponent } from "./icon-bell-bing";
import { IconBinanceComponent } from "./icon-binance";
import { IconBitcoinComponent } from "./icon-bitcoin";
import { IconBoltComponent } from "./icon-bolt";
import { IconBookComponent } from "./icon-book";
import { IconBookmarkComponent } from "./icon-bookmark";
import { IconBoxComponent } from "./icon-box";
import { IconCalendarComponent } from "./icon-calendar";
import { IconCameraComponent } from "./icon-camera";
import { IconCaretDownComponent } from "./icon-caret-down";
import { IconCaretsDownComponent } from "./icon-carets-down";
import { IconCashBanknotesComponent } from "./icon-cash-banknotes";
import { IconChartSquareComponent } from "./icon-chart-square";
import { IconChatDotComponent } from "./icon-chat-dot";
import { IconChatDotsComponent } from "./icon-chat-dots";
import { IconChatNotificationComponent } from "./icon-chat-notification";
import { IconChecksComponent } from "./icon-checks";
import { IconChromeComponent } from "./icon-chrome";
import { IconCircleCheckComponent } from "./icon-circle-check";
import { IconMenuAppsComponent } from "./menu/icon-menu-apps";
import { IconMenuAuthenticationComponent } from "./menu/icon-menu-authentication";
import { IconMenuCalendarComponent } from "./menu/icon-menu-calendar";
import { IconMenuChartsComponent } from "./menu/icon-menu-charts";
import { IconMenuChatComponent } from "./menu/icon-menu-chat";
import { IconMenuComponentsComponent } from "./menu/icon-menu-components";
import { IconMenuContactsComponent } from "./menu/icon-menu-contacts";
import { IconMenuDashboardComponent } from "./menu/icon-menu-dashboard";
import { IconMenuDatatablesComponent } from "./menu/icon-menu-datatables";
import { IconMenuDragAndDropComponent } from "./menu/icon-menu-drag-and-drop";
import { IconMenuElementsComponent } from "./menu/icon-menu-elements";
import { IconMenuFontIconsComponent } from "./menu/icon-menu-font-icons";
import { IconMenuFormsComponent } from "./menu/icon-menu-forms";
import { IconMenuInvoiceComponent } from "./menu/icon-menu-invoice";
import { IconMenuMailboxComponent } from "./menu/icon-menu-mailbox";
import { IconMenuMoreComponent } from "./menu/icon-menu-more";
import { IconMenuNotesComponent } from "./menu/icon-menu-notes";
import { IconMenuPagesComponent } from "./menu/icon-menu-pages";
import { IconMenuScrumboardComponent } from "./menu/icon-menu-scrumboard";
import { IconMenuTablesComponent } from "./menu/icon-menu-tables";
import { IconMenuTodoComponent } from "./menu/icon-menu-todo";
import { IconMenuUsersComponent } from "./menu/icon-menu-users";
import { IconMenuWidgetsComponent } from "./menu/icon-menu-widgets";
import { IconClipboardTextComponent } from "./icon-clipboard-text";
import { IconClockComponent } from "./icon-clock";
import { IconCloudDownloadComponent } from "./icon-cloud-download";
import { IconCodeComponent } from "./icon-code";
import { IconCodeFileComponent } from "./icon-code-file";
import { IconCoffeeComponent } from "./icon-coffee";
import { IconCopyComponent } from "./icon-copy";
import { IconCpuBoltComponent } from "./icon-cpu-bolt";
import { IconCreditCardComponent } from "./icon-credit-card";
import { IconDesktopComponent } from "./icon-desktop";
import { IconDollarSignComponent } from "./icon-dollar-sign";
import { IconDollarSignCircleComponent } from "./icon-dollar-sign-circle";
import { IconDownloadComponent } from "./icon-download";
import { IconDribbbleComponent } from "./icon-dribbble";
import { IconDropletComponent } from "./icon-droplet";
import { IconEditComponent } from "./icon-edit";
import { IconEthereumComponent } from "./icon-ethereum";
import { IconEyeComponent } from "./icon-eye";
import { IconFacebookComponent } from "./icon-facebook";
import { IconFacebookCircleComponent } from "./icon-facebook-circle";
import { IconFileComponent } from "./icon-file";
import { IconFolderComponent } from "./icon-folder";
import { IconFolderMinusComponent } from "./icon-folder-minus";
import { IconFolderPlusComponent } from "./icon-folder-plus";
import { IconGalleryComponent } from "./icon-gallery";
import { IconGithubComponent } from "./icon-github";
import { IconGlobeComponent } from "./icon-globe";
import { IconGoogleComponent } from "./icon-google";
import { IconHeartComponent } from "./icon-heart";
import { IconHelpCircleComponent } from "./icon-help-circle";
import { IconHomeComponent } from "./icon-home";
import { IconHorizontalDotsComponent } from "./icon-horizontal-dots";
import { IconInboxComponent } from "./icon-inbox";
import { IconInfoCircleComponent } from "./icon-info-circle";
import { IconInfoHexagonComponent } from "./icon-info-hexagon";
import { IconInfoTriangleComponent } from "./icon-info-triangle";
import { IconInstagramComponent } from "./icon-instagram";
import { IconLaptopComponent } from "./icon-laptop";
import { IconLayoutComponent } from "./icon-layout";
import { IconLayoutGridComponent } from "./icon-layout-grid";
import { IconLinkComponent } from "./icon-link";
import { IconLinkedinComponent } from "./icon-linkedin";
import { IconListCheckComponent } from "./icon-list-check";
import { IconLitecoinComponent } from "./icon-litecoin";
import { IconLockComponent } from "./icon-lock";
import { IconLockDotsComponent } from "./icon-lock-dots";
import { IconLoginComponent } from "./icon-login";
import { IconLogoutComponent } from "./icon-logout";
import { IconMailComponent } from "./icon-mail";
import { IconMailDotComponent } from "./icon-mail-dot";
import { IconMapPinComponent } from "./icon-map-pin";
import { IconMenuComponent } from "./icon-menu";
import { IconMessageComponent } from "./icon-message";
import { IconMessage2Component } from "./icon-message-2";
import { IconMessageDotsComponent } from "./icon-message-dots";
import { IconMessagesDotComponent } from "./icon-messages-dot";
import { IconMicrophoneOffComponent } from "./icon-microphone-off";
import { IconMinusComponent } from "./icon-minus";
import { IconMinusCircleComponent } from "./icon-minus-circle";
import { IconMoodSmileComponent } from "./icon-mood-smile";
import { IconMoonComponent } from "./icon-moon";
import { IconMultipleForwardRightComponent } from "./icon-multiple-forward-right";
import { IconNetflixComponent } from "./icon-netflix";
import { IconNotesComponent } from "./icon-notes";
import { IconNotesEditComponent } from "./icon-notes-edit";
import { IconOpenBookComponent } from "./icon-open-book";
import { IconPaperclipComponent } from "./icon-paperclip";
import { IconPencilComponent } from "./icon-pencil";
import { IconPencilPaperComponent } from "./icon-pencil-paper";
import { IconPhoneComponent } from "./icon-phone";
import { IconPhoneCallComponent } from "./icon-phone-call";
import { IconPlayCircleComponent } from "./icon-play-circle";
import { IconPlusComponent } from "./icon-plus";
import { IconPlusCircleComponent } from "./icon-plus-circle";
import { IconPrinterComponent } from "./icon-printer";
import { IconRefreshComponent } from "./icon-refresh";
import { IconRestoreComponent } from "./icon-restore";
import { IconRouterComponent } from "./icon-router";
import { IconSafariComponent } from "./icon-safari";
import { IconSaveComponent } from "./icon-save";
import { IconSearchComponent } from "./icon-search";
import { IconSendComponent } from "./icon-send";
import { IconServerComponent } from "./icon-server";
import { IconSettingsComponent } from "./icon-settings";
import { IconShareComponent } from "./icon-share";
import { IconShoppingBagComponent } from "./icon-shopping-bag";
import { IconShoppingCartComponent } from "./icon-shopping-cart";
import { IconSolanaComponent } from "./icon-solana";
import { IconSquareCheckComponent } from "./icon-square-check";
import { IconSquareRotatedComponent } from "./icon-square-rotated";
import { IconStarComponent } from "./icon-star";
import { IconSunComponent } from "./icon-sun";
import { IconTagComponent } from "./icon-tag";
import { IconTetherComponent } from "./icon-tether";
import { IconThumbUpComponent } from "./icon-thumb-up";
import { IconTrashComponent } from "./icon-trash";
import { IconTrashLinesComponent } from "./icon-trash-lines";
import { IconTrendingUpComponent } from "./icon-trending-up";
import { IconTwitterComponent } from "./icon-twitter";
import { IconTxtFileComponent } from "./icon-txt-file";
import { IconUserComponent } from "./icon-user";
import { IconUserPlusComponent } from "./icon-user-plus";
import { IconUsersComponent } from "./icon-users";
import { IconUsersGroupComponent } from "./icon-users-group";
import { IconVideoComponent } from "./icon-video";
import { IconWheelComponent } from "./icon-wheel";
import { IconXComponent } from "./icon-x";
import { IconXCircleComponent } from "./icon-x-circle";
import { IconZipFileComponent } from "./icon-zip-file";


// Map icon names to their corresponding component types
export const IconComponentMap: { [iconName: string]: any } = {
    'menu-apps': IconMenuAppsComponent,
    'menu-authentication': IconMenuAuthenticationComponent,
    'menu-calendar': IconMenuCalendarComponent,
    'menu-charts': IconMenuChartsComponent,
    'menu-chat': IconMenuChatComponent,
    'menu-components': IconMenuComponentsComponent,
    'menu-contacts': IconMenuContactsComponent,
    'menu-dashboard': IconMenuDashboardComponent,
    'menu-datatables': IconMenuDatatablesComponent,
    'menu-drag-and-drop': IconMenuDragAndDropComponent,
    'menu-elements': IconMenuElementsComponent,
    'menu-font-icons': IconMenuFontIconsComponent,
    'menu-forms': IconMenuFormsComponent,
    'menu-invoice': IconMenuInvoiceComponent,
    'menu-mailbox': IconMenuMailboxComponent,
    'menu-more': IconMenuMoreComponent,
    'menu-notes': IconMenuNotesComponent,
    'menu-pages': IconMenuPagesComponent,
    'menu-scrumboard': IconMenuScrumboardComponent,
    'menu-tables': IconMenuTablesComponent,
    'menu-todo': IconMenuTodoComponent,
    'menu-users': IconMenuUsersComponent,
    'menu-widgets': IconMenuWidgetsComponent,
    'airplay': IconAirplayComponent,
    'loader': IconLoaderComponent,
    'archive': IconArchiveComponent,
    'arrow-backward': IconArrowBackwardComponent,
    'arrow-forward': IconArrowForwardComponent,
    'arrow-left': IconArrowLeftComponent,
    'arrow-wave-left-up': IconArrowWaveLeftUpComponent,
    'at': IconAtComponent,
    'award': IconAwardComponent,
    'bar-chart': IconBarChartComponent,
    'bell': IconBellComponent,
    'bell-bing': IconBellBingComponent,
    'binance': IconBinanceComponent,
    'bitcoin': IconBitcoinComponent,
    'bolt': IconBoltComponent,
    'book': IconBookComponent,
    'bookmark': IconBookmarkComponent,
    'box': IconBoxComponent,
    'calendar': IconCalendarComponent,
    'camera': IconCameraComponent,
    'caret-down': IconCaretDownComponent,
    'carets-down': IconCaretsDownComponent,
    'cash-banknotes': IconCashBanknotesComponent,
    'chart-square': IconChartSquareComponent,
    'chat-dot': IconChatDotComponent,
    'chat-dots': IconChatDotsComponent,
    'chat-notification': IconChatNotificationComponent,
    'checks': IconChecksComponent,
    'chrome': IconChromeComponent,
    'circle-check': IconCircleCheckComponent,
    'clipboard-text': IconClipboardTextComponent,
    'clock': IconClockComponent,
    'cloud-download': IconCloudDownloadComponent,
    'code': IconCodeComponent,
    'coffee': IconCoffeeComponent,
    'copy': IconCopyComponent,
    'cpu-bolt': IconCpuBoltComponent,
    'credit-card': IconCreditCardComponent,
    'desktop': IconDesktopComponent,
    'dollar-sign': IconDollarSignComponent,
    'dollar-sign-circle': IconDollarSignCircleComponent,
    'download': IconDownloadComponent,
    'dribbble': IconDribbbleComponent,
    'droplet': IconDropletComponent,
    'edit': IconEditComponent,
    'ethereum': IconEthereumComponent,
    'eye': IconEyeComponent,
    'facebook': IconFacebookComponent,
    'facebook-circle': IconFacebookCircleComponent,
    'file': IconFileComponent,
    'folder': IconFolderComponent,
    'folder-minus': IconFolderMinusComponent,
    'folder-plus': IconFolderPlusComponent,
    'gallery': IconGalleryComponent,
    'github': IconGithubComponent,
    'globe': IconGlobeComponent,
    'google': IconGoogleComponent,
    'heart': IconHeartComponent,
    'help-circle': IconHelpCircleComponent,
    'home': IconHomeComponent,
    'horizontal-dots': IconHorizontalDotsComponent,
    'inbox': IconInboxComponent,
    'info-circle': IconInfoCircleComponent,
    'info-hexagon': IconInfoHexagonComponent,
    'info-triangle': IconInfoTriangleComponent,
    'instagram': IconInstagramComponent,
    'laptop': IconLaptopComponent,
    'layout': IconLayoutComponent,
    'layout-grid': IconLayoutGridComponent,
    'link': IconLinkComponent,
    'linkedin': IconLinkedinComponent,
    'list-check': IconListCheckComponent,
    'litecoin': IconLitecoinComponent,
    'lock': IconLockComponent,
    'lock-dots': IconLockDotsComponent,
    'login': IconLoginComponent,
    'logout': IconLogoutComponent,
    'mail': IconMailComponent,
    'mail-dot': IconMailDotComponent,
    'map-pin': IconMapPinComponent,
    'menu': IconMenuComponent,
    'message': IconMessageComponent,
    'message-2': IconMessage2Component,
    'message-dots': IconMessageDotsComponent,
    'messages-dot': IconMessagesDotComponent,
    'microphone-off': IconMicrophoneOffComponent,
    'minus': IconMinusComponent,
    'minus-circle': IconMinusCircleComponent,
    'mood-smile': IconMoodSmileComponent,
    'moon': IconMoonComponent,
    'multiple-forward-right': IconMultipleForwardRightComponent,
    'netflix': IconNetflixComponent,
    'notes': IconNotesComponent,
    'notes-edit': IconNotesEditComponent,
    'open-book': IconOpenBookComponent,
    'paperclip': IconPaperclipComponent,
    'pencil': IconPencilComponent,
    'pencil-paper': IconPencilPaperComponent,
    'phone': IconPhoneComponent,
    'phone-call': IconPhoneCallComponent,
    'play-circle': IconPlayCircleComponent,
    'plus': IconPlusComponent,
    'plus-circle': IconPlusCircleComponent,
    'printer': IconPrinterComponent,
    'refresh': IconRefreshComponent,
    'restore': IconRestoreComponent,
    'router': IconRouterComponent,
    'safari': IconSafariComponent,
    'save': IconSaveComponent,
    'search': IconSearchComponent,
    'send': IconSendComponent,
    'server': IconServerComponent,
    'settings': IconSettingsComponent,
    'share': IconShareComponent,
    'shopping-bag': IconShoppingBagComponent,
    'shopping-cart': IconShoppingCartComponent,
    'solana': IconSolanaComponent,
    'square-check': IconSquareCheckComponent,
    'square-rotated': IconSquareRotatedComponent,
    'star': IconStarComponent,
    'sun': IconSunComponent,
    'tag': IconTagComponent,
    'tether': IconTetherComponent,
    'thumb-up': IconThumbUpComponent,
    'trash': IconTrashComponent,
    'trash-lines': IconTrashLinesComponent,
    'trending-up': IconTrendingUpComponent,
    'twitter': IconTwitterComponent,
    'txt-file': IconTxtFileComponent,
    'user': IconUserComponent,
    'user-plus': IconUserPlusComponent,
    'users': IconUsersComponent,
    'users-group': IconUsersGroupComponent,
    'video': IconVideoComponent,
    'wheel': IconWheelComponent,
    'x': IconXComponent,
    'x-circle': IconXCircleComponent,
    'zip-file': IconZipFileComponent,
    'code-file': IconCodeFileComponent

    // Map other icon names to their respective component types
};
