import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { COMMON_CONSTANT } from '../constant/common.constant';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';
import { IdleService } from './idle.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    userRole: any;
    private jwtTokenSubject: BehaviorSubject<string | null>;
    private refreshTokenSubject!: BehaviorSubject<string | null>;
    constructor(private http: HttpClient, private router: Router, private idleService: IdleService) {
        this.jwtTokenSubject = new BehaviorSubject<string | null>(localStorage.getItem('jwtToken'));
        this.refreshTokenSubject = new BehaviorSubject<string | null>(localStorage.getItem('refreshToken'));
        // Start a timer to periodically check token expiration
        interval(6000).subscribe(() => {
            this.checkTokenExpiration();
        });
    }
    setUserDetails(userDetails: any) {
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
    }
    getUserData(attr: any = '') {
        let userDetails: any = localStorage.getItem('userDetails')
        userDetails = userDetails ? JSON.parse(userDetails) : {};
        return attr ? (userDetails[attr] ? userDetails[attr] : '') : userDetails;
    }
    // Method to check if the token has expired
    private checkTokenExpiration(): void {
        const expirationTime = this.getAttributeFromToken('exp') * 1000;
        if (expirationTime) {
            const expirationTimestamp = +expirationTime;
            const fiveMinutesInMs = 5 * 60 * 1000;
            const timeUntilExpiration = expirationTimestamp - Date.now();

            if (timeUntilExpiration <= fiveMinutesInMs) {
                if (this.idleService.isUserActive()) {
                    this.refreshToken();
                } else {
                    this.logout();
                }
            }
        }
    }
    // Method to logout and clear JWT token
    logout(): void {
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('menus');
        this.jwtTokenSubject.next(null);
        this.refreshTokenSubject.next(null);
        this.router.navigate(['/login']);
    }

    // Method to get the current JWT token
    getJwtToken(): string | null {
        return this.jwtTokenSubject.value;
    }

    // Method to set JWT token
    setJwtToken(token: string): void {
        localStorage.setItem('jwtToken', token);
        this.jwtTokenSubject.next(token);
    }

    // Method to get the current JWT token
    getRefreshToken(): string | null {
        return this.refreshTokenSubject.value;
    }

    // Method to set JWT token
    setRefreshToken(token: string): void {
        localStorage.setItem('refreshToken', token);
        this.refreshTokenSubject.next(token);
    }

    refreshToken() {
        let res = {
            "accessToken": this.getJwtToken(),
            "refreshToken": this.getRefreshToken(),
        }
        this.http.post(`${environment.baseUrl}/User/Refresh-token`, res).subscribe(
            (response: any) => {
                this.setJwtToken(response.token);
            },
            (error) => {
                // Handle error and possibly logout
                this.logout();
            }
        );
    }
    getDecodedAccessToken(token: any = this.getJwtToken()): any {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken;
        } catch (Error) {
            return null;
        }
    }
    // Method to check if user is authenticated
    isAuthenticated(): boolean {
        const token = this.getJwtToken();
        // Check if token exists and is not expired
        return !!token;
    }
    getUserRole(): string | null {
        let userDetail = this.getDecodedAccessToken();
        if (userDetail) {
            let userRole = userDetail.UserRole;
            switch (userRole) {
                case COMMON_CONSTANT.role.company:
                case COMMON_CONSTANT.role.companyUser:
                    return 'company'
                case COMMON_CONSTANT.role.admin:
                    return 'admin'
                default:
                    break;
            }
            return userRole;
        } else {
            this.logout();
            return null;
        }

    }
    getAttributeFromToken(attr: any) {
        let tokenDetail = this.getDecodedAccessToken();
        return tokenDetail && tokenDetail[attr] ? tokenDetail[attr] : '';
    }
    setUserMenus(menus: any) {
        localStorage.setItem('menus', JSON.stringify(menus));
    }
    getUserMenus() {
        let menus = localStorage.getItem('menus');
        return menus ? JSON.parse(menus) : menus;
    }
}
