<div>
    <div class="mb-6 grid gap-6 xl:grid-cols-3">
        <div class="panel h-full xl:col-span-2">
            <div class="mb-5 flex items-center justify-between dark:text-white-light">
                <h5 class="text-lg font-semibold">Revenue</h5>
                <div class="dropdown ltr:ml-auto rtl:mr-auto">
                    <div hlMenu>
                        <a hlMenuButton href="javascript:;" class="align-middle">
                            <icon-horizontal-dots class="text-black/70 hover:!text-primary dark:text-white/70" />
                        </a>
                        <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">Weekly</a>
                            </li>
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">Monthly</a>
                            </li>
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">Yearly</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <p class="text-lg dark:text-white-light/90">Total Profit <span class="ml-2 text-primary">$10,840</span></p>
            <div class="relative overflow-hidden">
                <!-- [dropShadow]="revenueChart.dropShadow" -->
                <apx-chart
                    [series]="revenueChart.series"
                    [chart]="revenueChart.chart"
                    [dataLabels]="revenueChart.dataLabels"
                    [stroke]="revenueChart.stroke"
                    [colors]="revenueChart.colors"
                    [markers]="revenueChart.markers"
                    [labels]="revenueChart.labels"
                    [xaxis]="revenueChart.xaxis"
                    [yaxis]="revenueChart.yaxis"
                    [grid]="revenueChart.grid"
                    [legend]="revenueChart.legend"
                    [tooltip]="revenueChart.tooltip"
                    [fill]="revenueChart.fill"
                    class="rounded-lg bg-white dark:bg-black"
                >
                    <!-- loader -->
                    <div class="grid min-h-[325px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                        <span class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"></span>
                    </div>
                </apx-chart>
            </div>
        </div>

        <div class="panel h-full">
            <div class="mb-5 flex items-center">
                <h5 class="text-lg font-semibold dark:text-white-light">Sales By Category</h5>
            </div>
            <div class="overflow-hidden">
                <apx-chart
                    [series]="salesByCategory.series"
                    [chart]="salesByCategory.chart"
                    [dataLabels]="salesByCategory.dataLabels"
                    [stroke]="salesByCategory.stroke"
                    [colors]="salesByCategory.colors"
                    [legend]="salesByCategory.legend"
                    [plotOptions]="salesByCategory.plotOptions"
                    [labels]="salesByCategory.labels"
                    [states]="salesByCategory.states"
                    class="rounded-lg bg-white dark:bg-black"
                >
                    <!-- loader -->
                    <div class="grid min-h-[460px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                        <span class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"></span>
                    </div>
                </apx-chart>
            </div>
        </div>
    </div>

    <div class="mb-6 grid gap-6 sm:grid-cols-2 xl:grid-cols-3">
        <div class="panel h-full sm:col-span-2 xl:col-span-1">
            <div class="mb-5 flex items-center">
                <h5 class="text-lg font-semibold dark:text-white-light">
                    Daily Sales <span class="block text-sm font-normal text-white-dark">Go to columns for details.</span>
                </h5>
                <div class="relative ltr:ml-auto rtl:mr-auto">
                    <div class="grid h-11 w-11 place-content-center rounded-full bg-[#ffeccb] text-warning dark:bg-warning dark:text-[#ffeccb]">
                        <icon-dollar-sign />
                    </div>
                </div>
            </div>
            <div class="overflow-hidden">
                <apx-chart
                    [series]="dailySales.series"
                    [chart]="dailySales.chart"
                    [dataLabels]="dailySales.dataLabels"
                    [stroke]="dailySales.stroke"
                    [colors]="dailySales.colors"
                    [responsive]="dailySales.responsive"
                    [xaxis]="dailySales.xaxis"
                    [yaxis]="dailySales.yaxis"
                    [fill]="dailySales.fill"
                    [plotOptions]="dailySales.plotOptions"
                    [legend]="dailySales.legend"
                    [grid]="dailySales.grid"
                    class="rounded-lg bg-white dark:bg-black"
                >
                    <!-- loader -->
                    <div class="grid min-h-[175px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                        <span class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"></span>
                    </div>
                </apx-chart>
            </div>
        </div>

        <div class="panel h-full">
            <div class="mb-5 flex items-center dark:text-white-light">
                <h5 class="text-lg font-semibold">Summary</h5>
                <div class="dropdown ltr:ml-auto rtl:mr-auto">
                    <div hlMenu>
                        <a href="javascript:;" hlMenuButton class="align-middle">
                            <icon-horizontal-dots class="h-5 w-5 text-black/70 hover:!text-primary dark:text-white/70" />
                        </a>
                        <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">View Report</a>
                            </li>
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">Edit Report</a>
                            </li>
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">Mark as Done</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="space-y-9">
                <div class="flex items-center">
                    <div class="h-9 w-9 ltr:mr-3 rtl:ml-3">
                        <div
                            class="grid h-9 w-9 place-content-center rounded-full bg-secondary-light text-secondary dark:bg-secondary dark:text-secondary-light"
                        >
                            <icon-inbox />
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="mb-2 flex font-semibold text-white-dark">
                            <h6>Income</h6>
                            <p class="ltr:ml-auto rtl:mr-auto">$92,600</p>
                        </div>
                        <div class="h-2 rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]">
                            <div class="h-full w-11/12 rounded-full bg-gradient-to-r from-[#7579ff] to-[#b224ef]"></div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="h-9 w-9 ltr:mr-3 rtl:ml-3">
                        <div class="grid h-9 w-9 place-content-center rounded-full bg-success-light text-success dark:bg-success dark:text-success-light">
                            <icon-tag />
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="mb-2 flex font-semibold text-white-dark">
                            <h6>Profit</h6>
                            <p class="ltr:ml-auto rtl:mr-auto">$37,515</p>
                        </div>
                        <div class="h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]">
                            <div class="h-full w-full rounded-full bg-gradient-to-r from-[#3cba92] to-[#0ba360]" style="width: 65%"></div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="h-9 w-9 ltr:mr-3 rtl:ml-3">
                        <div class="grid h-9 w-9 place-content-center rounded-full bg-warning-light text-warning dark:bg-warning dark:text-warning-light">
                            <icon-credit-card />
                        </div>
                    </div>
                    <div class="flex-1">
                        <div class="mb-2 flex font-semibold text-white-dark">
                            <h6>Expenses</h6>
                            <p class="ltr:ml-auto rtl:mr-auto">$55,085</p>
                        </div>
                        <div class="h-2 w-full rounded-full bg-dark-light shadow dark:bg-[#1b2e4b]">
                            <div class="h-full w-full rounded-full bg-gradient-to-r from-[#f09819] to-[#ff5858]" style="width: 80%"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel h-full p-0">
            <div class="absolute flex w-full items-center justify-between p-5">
                <div class="relative">
                    <div class="flex h-11 w-11 items-center justify-center rounded-lg bg-success-light text-success dark:bg-success dark:text-success-light">
                        <icon-shopping-cart />
                    </div>
                </div>
                <h5 class="text-2xl font-semibold ltr:text-right rtl:text-left dark:text-white-light">
                    3,192
                    <span class="block text-sm font-normal">Total Orders</span>
                </h5>
            </div>
            <div class="overflow-hidden">
                <apx-chart
                    [series]="totalOrders.series"
                    [chart]="totalOrders.chart"
                    [stroke]="totalOrders.stroke"
                    [colors]="totalOrders.colors"
                    [labels]="totalOrders.labels"
                    [yaxis]="totalOrders.yaxis"
                    [grid]="totalOrders.grid"
                    [fill]="totalOrders.fill"
                    [tooltip]="totalOrders.tooltip"
                    class="rounded-lg bg-white dark:bg-black"
                >
                    <!-- loader -->
                    <div class="grid min-h-[290px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                        <span class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"></span>
                    </div>
                </apx-chart>
            </div>
        </div>
    </div>

    <div class="mb-6 grid gap-6 sm:grid-cols-2 xl:grid-cols-3">
        <div class="panel h-full pb-0 sm:col-span-2 xl:col-span-1">
            <h5 class="mb-5 text-lg font-semibold dark:text-white-light">Recent Activities</h5>

            <ng-scrollbar class="relative mb-4 !h-[290px] ltr:-mr-3 ltr:pr-3 rtl:-ml-3 rtl:pl-3">
                <div class="cursor-pointer text-sm">
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-primary ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Updated Server Logs</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">Just Now</div>

                        <span
                            class="badge badge-outline-primary absolute bg-primary-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Pending</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-success ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Send Mail to HR and Admin</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">2 min ago</div>

                        <span
                            class="badge badge-outline-success absolute bg-success-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Completed</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-danger ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Backup Files EOD</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">14:00</div>

                        <span
                            class="badge badge-outline-danger absolute bg-danger-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Pending</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-black ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Collect documents from Sara</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">16:00</div>

                        <span
                            class="badge badge-outline-dark absolute bg-dark-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Completed</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-warning ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Conference call with Marketing Manager.</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">17:00</div>

                        <span
                            class="badge badge-outline-warning absolute bg-warning-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >In progress</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-info ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Rebooted Server</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">17:00</div>

                        <span
                            class="badge badge-outline-info absolute bg-info-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Completed</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-secondary ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Send contract details to Freelancer</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">18:00</div>

                        <span
                            class="badge badge-outline-secondary absolute bg-secondary-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Pending</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-primary ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Updated Server Logs</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">Just Now</div>

                        <span
                            class="badge badge-outline-primary absolute bg-primary-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Pending</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-success ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Send Mail to HR and Admin</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">2 min ago</div>

                        <span
                            class="badge badge-outline-success absolute bg-success-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Completed</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-danger ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Backup Files EOD</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">14:00</div>

                        <span
                            class="badge badge-outline-danger absolute bg-danger-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Pending</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-black ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Collect documents from Sara</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">16:00</div>

                        <span
                            class="badge badge-outline-dark absolute bg-dark-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Completed</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-warning ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Conference call with Marketing Manager.</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">17:00</div>

                        <span
                            class="badge badge-outline-warning absolute bg-warning-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >In progress</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-info ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Rebooted Server</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">17:00</div>

                        <span
                            class="badge badge-outline-info absolute bg-info-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Completed</span
                        >
                    </div>
                    <div class="group relative flex items-center py-1.5">
                        <div class="h-1.5 w-1.5 rounded-full bg-secondary ltr:mr-1 rtl:ml-1.5"></div>
                        <div class="flex-1">Send contract details to Freelancer</div>
                        <div class="text-xs text-white-dark ltr:ml-auto rtl:mr-auto dark:text-gray-500">18:00</div>

                        <span
                            class="badge badge-outline-secondary absolute bg-secondary-light text-xs opacity-0 group-hover:opacity-100 ltr:right-0 rtl:left-0 dark:bg-[#0e1726]"
                            >Pending</span
                        >
                    </div>
                </div>
            </ng-scrollbar>
            <div class="border-t border-white-light dark:border-white/10">
                <a href="javascript:;" class="group group flex items-center justify-center p-4 font-semibold hover:text-primary">
                    View All
                    <icon-arrow-left
                        class="transition duration-300 group-hover:translate-x-1 ltr:ml-1 rtl:mr-1 rtl:rotate-180 rtl:group-hover:-translate-x-1"
                    />
                </a>
            </div>
        </div>

        <div class="panel h-full">
            <div class="mb-5 flex items-center justify-between dark:text-white-light">
                <h5 class="text-lg font-semibold">Transactions</h5>
                <div class="dropdown ltr:ml-auto rtl:mr-auto">
                    <div hlMenu>
                        <a href="javascript:;" hlMenuButton class="align-middle">
                            <icon-horizontal-dots class="text-black/70 hover:!text-primary dark:text-white/70" />
                        </a>
                        <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">View Report</a>
                            </li>
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">Edit Report</a>
                            </li>
                            <li>
                                <a href="javascript:;" *hlMenuItem="let menuItem">Mark as Done</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div class="space-y-6">
                    <div class="flex">
                        <span
                            class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-success-light text-base text-success dark:bg-success dark:text-success-light"
                            >SP</span
                        >
                        <div class="flex-1 px-3">
                            <div>Shaun Park</div>
                            <div class="text-xs text-white-dark dark:text-gray-500">10 Jan 1:00PM</div>
                        </div>
                        <span class="whitespace-pre px-1 text-base text-success ltr:ml-auto rtl:mr-auto">+$36.11</span>
                    </div>
                    <div class="flex">
                        <span
                            class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-warning-light text-warning dark:bg-warning dark:text-warning-light"
                        >
                            <icon-cash-banknotes />
                        </span>
                        <div class="flex-1 px-3">
                            <div>Cash withdrawal</div>
                            <div class="text-xs text-white-dark dark:text-gray-500">04 Jan 1:00PM</div>
                        </div>
                        <span class="whitespace-pre px-1 text-base text-danger ltr:ml-auto rtl:mr-auto">-$16.44</span>
                    </div>
                    <div class="flex">
                        <span class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-danger-light text-danger dark:bg-danger dark:text-danger-light">
                            <icon-user class="h-6 w-6" />
                        </span>
                        <div class="flex-1 px-3">
                            <div>Amy Diaz</div>
                            <div class="text-xs text-white-dark dark:text-gray-500">10 Jan 1:00PM</div>
                        </div>
                        <span class="whitespace-pre px-1 text-base text-success ltr:ml-auto rtl:mr-auto">+$66.44</span>
                    </div>
                    <div class="flex">
                        <span
                            class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-secondary-light text-secondary dark:bg-secondary dark:text-secondary-light"
                        >
                            <icon-netflix />
                        </span>
                        <div class="flex-1 px-3">
                            <div>Netflix</div>
                            <div class="text-xs text-white-dark dark:text-gray-500">04 Jan 1:00PM</div>
                        </div>
                        <span class="whitespace-pre px-1 text-base text-danger ltr:ml-auto rtl:mr-auto">-$32.00</span>
                    </div>
                    <div class="flex">
                        <span class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-info-light text-base text-info dark:bg-info dark:text-info-light"
                            >DA</span
                        >
                        <div class="flex-1 px-3">
                            <div>Daisy Anderson</div>
                            <div class="text-xs text-white-dark dark:text-gray-500">10 Jan 1:00PM</div>
                        </div>
                        <span class="whitespace-pre px-1 text-base text-success ltr:ml-auto rtl:mr-auto">+$10.08</span>
                    </div>
                    <div class="flex">
                        <span
                            class="grid h-9 w-9 shrink-0 place-content-center rounded-md bg-primary-light text-primary dark:bg-primary dark:text-primary-light"
                        >
                            <icon-bolt />
                        </span>
                        <div class="flex-1 px-3">
                            <div>Electricity Bill</div>
                            <div class="text-xs text-white-dark dark:text-gray-500">04 Jan 1:00PM</div>
                        </div>
                        <span class="whitespace-pre px-1 text-base text-danger ltr:ml-auto rtl:mr-auto">-$22.00</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel h-full overflow-hidden border-0 p-0">
            <div class="min-h-[190px] bg-gradient-to-r from-[#4361ee] to-[#160f6b] p-6">
                <div class="mb-6 flex items-center justify-between">
                    <div class="flex items-center rounded-full bg-black/50 p-1 font-semibold text-white ltr:pr-3 rtl:pl-3">
                        <img
                            class="block h-8 w-8 rounded-full border-2 border-white/50 object-cover ltr:mr-1 rtl:ml-1"
                            src="/assets/images/profile-34.jpeg"
                            alt=""
                        />
                        Alan Green
                    </div>
                    <button
                        type="button"
                        class="flex h-9 w-9 items-center justify-between rounded-md bg-black text-white hover:opacity-80 ltr:ml-auto rtl:mr-auto"
                    >
                        <icon-plus class="m-auto h-6 w-6" />
                    </button>
                </div>
                <div class="flex items-center justify-between text-white">
                    <p class="text-xl">Wallet Balance</p>
                    <h5 class="text-2xl ltr:ml-auto rtl:mr-auto"><span class="text-white-light">$</span>2953</h5>
                </div>
            </div>
            <div class="-mt-12 grid grid-cols-2 gap-2 px-8">
                <div class="rounded-md bg-white px-4 py-2.5 shadow dark:bg-[#060818]">
                    <span class="mb-4 flex items-center justify-between dark:text-white"
                        >Received
                        <icon-caret-down class="h-4 w-4 rotate-180 text-success" />
                    </span>
                    <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">$97.99</div>
                </div>
                <div class="rounded-md bg-white px-4 py-2.5 shadow dark:bg-[#060818]">
                    <span class="mb-4 flex items-center justify-between dark:text-white"
                        >Spent
                        <icon-caret-down class="h-4 w-4 text-danger" />
                    </span>
                    <div class="btn w-full border-0 bg-[#ebedf2] py-1 text-base text-[#515365] shadow-none dark:bg-black dark:text-[#bfc9d4]">$53.00</div>
                </div>
            </div>
            <div class="p-5">
                <div class="mb-5">
                    <span
                        class="rounded-full bg-[#1b2e4b] px-4 py-1.5 text-xs text-white before:inline-block before:h-1.5 before:w-1.5 before:rounded-full before:bg-white ltr:before:mr-2 rtl:before:ml-2"
                        >Pending</span
                    >
                </div>
                <div class="mb-5 space-y-1">
                    <div class="flex items-center justify-between">
                        <p class="font-semibold text-[#515365]">Netflix</p>
                        <p class="text-base"><span>$ </span> <span class="font-semibold">13.85</span></p>
                    </div>
                    <div class="flex items-center justify-between">
                        <p class="font-semibold text-[#515365]">BlueHost VPN</p>
                        <p class="text-base"><span>$ </span> <span class="font-semibold">15.66</span></p>
                    </div>
                </div>
                <div class="flex justify-around px-2 text-center">
                    <button type="button" class="btn btn-secondary ltr:mr-2 rtl:ml-2">View Details</button>
                    <button type="button" class="btn btn-success">Pay Now $29.51</button>
                </div>
            </div>
        </div>
    </div>

    <div class="mb-6 grid grid-cols-1 gap-6 lg:grid-cols-2">
        <div class="panel h-full w-full">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white-light">Recent Orders</h5>
            </div>
            <div class="table-responsive">
                <table>
                    <thead>
                        <tr>
                            <th class="ltr:rounded-l-md rtl:rounded-r-md">Customer</th>
                            <th>Product</th>
                            <th>Invoice</th>
                            <th>Price</th>
                            <th class="ltr:rounded-r-md rtl:rounded-l-md">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="min-w-[150px] text-black dark:text-white">
                                <div class="flex items-center">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/profile-6.jpeg" alt="avatar" />
                                    <span class="whitespace-nowrap">Luke Ivory</span>
                                </div>
                            </td>
                            <td class="text-primary">Headphone</td>
                            <td><a routerLink="/apps/invoice/preview">#46894</a></td>
                            <td>$56.07</td>
                            <td><span class="badge bg-success shadow-md dark:group-hover:bg-transparent">Paid</span></td>
                        </tr>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="text-black dark:text-white">
                                <div class="flex items-center">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/profile-7.jpeg" alt="avatar" />
                                    <span class="whitespace-nowrap">Andy King</span>
                                </div>
                            </td>
                            <td class="text-info">Nike Sport</td>
                            <td><a routerLink="/apps/invoice/preview">#76894</a></td>
                            <td>$126.04</td>
                            <td><span class="badge bg-secondary shadow-md dark:group-hover:bg-transparent">Shipped</span></td>
                        </tr>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="text-black dark:text-white">
                                <div class="flex items-center">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/profile-8.jpeg" alt="avatar" />
                                    <span class="whitespace-nowrap">Laurie Fox</span>
                                </div>
                            </td>
                            <td class="text-warning">Sunglasses</td>
                            <td><a routerLink="/apps/invoice/preview">#66894</a></td>
                            <td>$56.07</td>
                            <td><span class="badge bg-success shadow-md dark:group-hover:bg-transparent">Paid</span></td>
                        </tr>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="text-black dark:text-white">
                                <div class="flex items-center">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/profile-9.jpeg" alt="avatar" />
                                    <span class="whitespace-nowrap">Ryan Collins</span>
                                </div>
                            </td>
                            <td class="text-danger">Sport</td>
                            <td><a routerLink="/apps/invoice/preview">#75844</a></td>
                            <td>$110.00</td>
                            <td><span class="badge bg-secondary shadow-md dark:group-hover:bg-transparent">Shipped</span></td>
                        </tr>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="text-black dark:text-white">
                                <div class="flex items-center">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/profile-10.jpeg" alt="avatar" />
                                    <span class="whitespace-nowrap">Irene Collins</span>
                                </div>
                            </td>
                            <td class="text-secondary">Speakers</td>
                            <td><a routerLink="/apps/invoice/preview">#46894</a></td>
                            <td>$56.07</td>
                            <td><span class="badge bg-success shadow-md dark:group-hover:bg-transparent">Paid</span></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="panel h-full w-full">
            <div class="mb-5 flex items-center justify-between">
                <h5 class="text-lg font-semibold dark:text-white-light">Top Selling Product</h5>
            </div>
            <div class="table-responsive">
                <table>
                    <thead>
                        <tr class="border-b-0">
                            <th class="ltr:rounded-l-md rtl:rounded-r-md">Product</th>
                            <th>Price</th>
                            <th>Discount</th>
                            <th>Sold</th>
                            <th class="ltr:rounded-r-md rtl:rounded-l-md">Source</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="min-w-[150px] text-black dark:text-white">
                                <div class="flex">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/product-headphones.jpg" alt="avatar" />
                                    <p class="whitespace-nowrap">Headphone <span class="block text-xs text-primary">Digital</span></p>
                                </div>
                            </td>
                            <td>$168.09</td>
                            <td>$60.09</td>
                            <td>170</td>
                            <td>
                                <a class="flex items-center text-danger" href="javascript:;">
                                    <icon-multiple-forward-right class="ltr:mr-1 rtl:ml-1 rtl:rotate-180" />

                                    Direct
                                </a>
                            </td>
                        </tr>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="text-black dark:text-white">
                                <div class="flex">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/product-shoes.jpg" alt="avatar" />
                                    <p class="whitespace-nowrap">Shoes <span class="block text-xs text-warning">Faishon</span></p>
                                </div>
                            </td>
                            <td>$126.04</td>
                            <td>$47.09</td>
                            <td>130</td>
                            <td>
                                <a class="flex items-center text-success" href="javascript:;">
                                    <icon-multiple-forward-right class="ltr:mr-1 rtl:ml-1 rtl:rotate-180" />
                                    Google
                                </a>
                            </td>
                        </tr>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="text-black dark:text-white">
                                <div class="flex">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/product-watch.jpg" alt="avatar" />
                                    <p class="whitespace-nowrap">Watch <span class="block text-xs text-danger">Accessories</span></p>
                                </div>
                            </td>
                            <td>$56.07</td>
                            <td>$20.00</td>
                            <td>66</td>
                            <td>
                                <a class="flex items-center text-warning" href="javascript:;">
                                    <icon-multiple-forward-right class="ltr:mr-1 rtl:ml-1 rtl:rotate-180" />
                                    Ads
                                </a>
                            </td>
                        </tr>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="text-black dark:text-white">
                                <div class="flex">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/product-laptop.jpg" alt="avatar" />
                                    <p class="whitespace-nowrap">Laptop <span class="block text-xs text-primary">Digital</span></p>
                                </div>
                            </td>
                            <td>$110.00</td>
                            <td>$33.00</td>
                            <td>35</td>
                            <td>
                                <a class="flex items-center text-secondary" href="javascript:;">
                                    <icon-multiple-forward-right class="ltr:mr-1 rtl:ml-1 rtl:rotate-180" />
                                    Email
                                </a>
                            </td>
                        </tr>
                        <tr class="group text-white-dark hover:text-black dark:hover:text-white-light/90">
                            <td class="text-black dark:text-white">
                                <div class="flex">
                                    <img class="h-8 w-8 rounded-md object-cover ltr:mr-3 rtl:ml-3" src="/assets/images/product-camera.jpg" alt="avatar" />
                                    <p class="whitespace-nowrap">Camera <span class="block text-xs text-primary">Digital</span></p>
                                </div>
                            </td>
                            <td>$56.07</td>
                            <td>$26.04</td>
                            <td>30</td>
                            <td>
                                <a class="flex items-center text-primary" href="javascript:;">
                                    <icon-multiple-forward-right class="ltr:mr-1 rtl:ml-1 rtl:rotate-180" />
                                    Referral
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="mb-6 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <div class="panel h-full sm:col-span-2 lg:col-span-1">
            <!-- statistics -->
            <div class="mb-5 flex items-center justify-between dark:text-white-light">
                <h5 class="text-lg font-semibold">Statistics</h5>
                <div hlMenu class="dropdown">
                    <button type="button" hlMenuButton>
                        <icon-horizontal-dots class="text-black/70 hover:!text-primary dark:text-white/70" />
                    </button>
                    <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">This Week</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Last Week</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">This Month</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Last Month</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="grid gap-8 text-sm font-bold text-[#515365] sm:grid-cols-2">
                <div>
                    <div>
                        <div>Total Visits</div>
                        <div class="text-lg text-[#f8538d]">423,964</div>
                    </div>
                    <div class="overflow-hidden">
                        <apx-chart
                            [series]="totalVisit.series"
                            [chart]="totalVisit.chart"
                            [stroke]="totalVisit.stroke"
                            [colors]="totalVisit.colors"
                            [grid]="totalVisit.grid"
                            [tooltip]="totalVisit.tooltip"
                            class="rounded-lg bg-white dark:bg-black"
                        >
                            <!-- loader -->
                            <div class="grid min-h-[58px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                                <span
                                    class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"
                                ></span>
                            </div>
                        </apx-chart>
                    </div>
                </div>

                <div>
                    <div>
                        <div>Paid Visits</div>
                        <div class="text-lg text-[#f8538d]">7,929</div>
                    </div>
                    <div class="overflow-hidden">
                        <apx-chart
                            [series]="paidVisit.series"
                            [chart]="paidVisit.chart"
                            [stroke]="paidVisit.stroke"
                            [colors]="paidVisit.colors"
                            [grid]="paidVisit.grid"
                            [tooltip]="paidVisit.tooltip"
                            class="rounded-lg bg-white dark:bg-black"
                        >
                            <!-- loader -->
                            <div class="grid min-h-[58px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                                <span
                                    class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"
                                ></span>
                            </div>
                        </apx-chart>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel h-full">
            <div class="mb-5 flex items-center justify-between dark:text-white-light">
                <h5 class="text-lg font-semibold">Expenses</h5>
                <div hlMenu class="dropdown">
                    <button type="button" hlMenuButton>
                        <icon-horizontal-dots class="text-black/70 hover:!text-primary dark:text-white/70" />
                    </button>
                    <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">This Week</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Last Week</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">This Month</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Last Month</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="my-10 text-3xl font-bold text-[#e95f2b]">
                <span class="ltr:mr-2 rtl:ml-2">$ 45,141</span>
                <span class="text-sm text-black ltr:mr-1 rtl:ml-1 dark:text-white-light">this week </span>
                <icon-trending-up class="inline text-success" />
            </div>
            <div class="flex items-center justify-between">
                <div class="h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
                    <div
                        class="relative h-full w-full rounded-full bg-gradient-to-r from-[#4361ee] to-[#805dca] before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
                        style="width: 65%"
                    ></div>
                </div>
                <span class="ltr:ml-5 rtl:mr-5 dark:text-white-light">57%</span>
            </div>
        </div>

        <div
            class="panel grid h-full grid-cols-1 content-between overflow-hidden before:absolute before:-right-44 before:bottom-0 before:top-0 before:m-auto before:h-96 before:w-96 before:rounded-full before:bg-[#1937cc]"
            style="background: linear-gradient(0deg, #00c6fb -227%, #005bea) !important"
        >
            <div class="z-[7] mb-16 flex items-start justify-between text-white-light">
                <h5 class="text-lg font-semibold">Total Balance</h5>

                <div class="relative whitespace-nowrap text-xl">
                    $ 41,741.42
                    <span class="mt-1 table rounded bg-[#4361ee] p-1 text-xs text-[#d3d3d3] ltr:ml-auto rtl:mr-auto">+ 2453</span>
                </div>
            </div>
            <div class="z-10 flex items-center justify-between">
                <div class="flex items-center justify-between">
                    <a
                        href="javascript:;"
                        class="place-content-center rounded p-1 text-white-light shadow-[0_0_2px_0_#bfc9d4] hover:bg-[#1937cc] ltr:mr-2 rtl:ml-2"
                    >
                        <icon-plus />
                    </a>
                    <a href="javascript:;" class="grid place-content-center rounded p-1 text-white-light shadow-[0_0_2px_0_#bfc9d4] hover:bg-[#1937cc]">
                        <icon-credit-card />
                    </a>
                </div>
                <a href="javascript:;" class="z-10 rounded p-1 text-white-light shadow-[0_0_2px_0_#bfc9d4] hover:bg-[#4361ee]"> Upgrade </a>
            </div>
        </div>
    </div>

    <div class="mb-6 grid gap-6 lg:grid-cols-3">
        <div class="panel h-full p-0 lg:col-span-2">
            <div class="mb-5 flex items-start justify-between border-b border-[#e0e6ed] p-5 dark:border-[#1b2e4b] dark:text-white-light">
                <h5 class="text-lg font-semibold">Unique Visitors</h5>
                <div hlMenu class="dropdown">
                    <button type="button" hlMenuButton>
                        <icon-horizontal-dots class="text-black/70 hover:!text-primary dark:text-white/70" />
                    </button>
                    <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">View</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Update</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Download</a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- [dropShadow]="uniqueVisitor.dropShadow" -->
            <div class="overflow-hidden">
                <apx-chart
                    [series]="uniqueVisitor.series"
                    [chart]="uniqueVisitor.chart"
                    [dataLabels]="uniqueVisitor.dataLabels"
                    [stroke]="uniqueVisitor.stroke"
                    [colors]="uniqueVisitor.colors"
                    [plotOptions]="uniqueVisitor.plotOptions"
                    [legend]="uniqueVisitor.legend"
                    [grid]="uniqueVisitor.grid"
                    [xaxis]="uniqueVisitor.xaxis"
                    [yaxis]="uniqueVisitor.yaxis"
                    [fill]="uniqueVisitor.fill"
                    [tooltip]="uniqueVisitor.tooltip"
                    class="rounded-lg bg-white dark:bg-black"
                >
                    <!-- loader -->
                    <div class="grid min-h-[360px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                        <span class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"></span>
                    </div>
                </apx-chart>
            </div>
        </div>

        <div class="panel h-full">
            <div class="-mx-5 mb-5 flex items-start justify-between border-b border-[#e0e6ed] p-5 pt-0 dark:border-[#1b2e4b] dark:text-white-light">
                <h5 class="text-lg font-semibold">Activity Log</h5>
                <div hlMenu class="dropdown">
                    <button type="button" hlMenuButton>
                        <icon-horizontal-dots class="text-black/70 hover:!text-primary dark:text-white/70" />
                    </button>
                    <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">View All</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Mark as Read</a>
                        </li>
                    </ul>
                </div>
            </div>
            <ng-scrollbar class="relative !h-[360px] ltr:-mr-3 ltr:pr-3 rtl:-ml-3 rtl:pl-3">
                <div class="space-y-7">
                    <div class="flex">
                        <div
                            class="relative z-10 shrink-0 before:absolute before:left-4 before:top-10 before:h-[calc(100%-24px)] before:w-[2px] before:bg-white-dark/30 ltr:mr-2 rtl:ml-2"
                        >
                            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-secondary text-white shadow shadow-secondary">
                                <icon-plus class="h-4 w-4" />
                            </div>
                        </div>
                        <div>
                            <h5 class="font-semibold dark:text-white-light">
                                New project created : <a href="javascript:;" class="text-success">[P3 LogiQ Admin Template]</a>
                            </h5>
                            <p class="text-xs text-white-dark">27 Feb, 2020</p>
                        </div>
                    </div>
                    <div class="flex">
                        <div
                            class="relative z-10 shrink-0 before:absolute before:left-4 before:top-10 before:h-[calc(100%-24px)] before:w-[2px] before:bg-white-dark/30 ltr:mr-2 rtl:ml-2"
                        >
                            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-success text-white shadow-success">
                                <icon-mail class="h-4 w-4" />
                            </div>
                        </div>
                        <div>
                            <h5 class="font-semibold dark:text-white-light">
                                Mail sent to <a href="javascript:;" class="text-white-dark">HR</a> and
                                <a href="javascript:;" class="text-white-dark">Admin</a>
                            </h5>
                            <p class="text-xs text-white-dark">28 Feb, 2020</p>
                        </div>
                    </div>
                    <div class="flex">
                        <div
                            class="relative z-10 shrink-0 before:absolute before:left-4 before:top-10 before:h-[calc(100%-24px)] before:w-[2px] before:bg-white-dark/30 ltr:mr-2 rtl:ml-2"
                        >
                            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white">
                                <icon-checks class="h-4 w-4" />
                            </div>
                        </div>
                        <div>
                            <h5 class="font-semibold dark:text-white-light">Server Logs Updated</h5>
                            <p class="text-xs text-white-dark">27 Feb, 2020</p>
                        </div>
                    </div>
                    <div class="flex">
                        <div
                            class="relative z-10 shrink-0 before:absolute before:left-4 before:top-10 before:h-[calc(100%-24px)] before:w-[2px] before:bg-white-dark/30 ltr:mr-2 rtl:ml-2"
                        >
                            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-danger text-white">
                                <icon-checks class="h-4 w-4" />
                            </div>
                        </div>
                        <div>
                            <h5 class="font-semibold dark:text-white-light">
                                Task Completed : <a href="javascript:;" class="text-success">[Backup Files EOD]</a>
                            </h5>
                            <p class="text-xs text-white-dark">01 Mar, 2020</p>
                        </div>
                    </div>
                    <div class="flex">
                        <div
                            class="relative z-10 shrink-0 before:absolute before:left-4 before:top-10 before:h-[calc(100%-24px)] before:w-[2px] before:bg-white-dark/30 ltr:mr-2 rtl:ml-2"
                        >
                            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-warning text-white">
                                <icon-file class="h-4 w-4" />
                            </div>
                        </div>
                        <div>
                            <h5 class="font-semibold dark:text-white-light">Documents Submitted from <a href="javascript:;">Sara</a></h5>
                            <p class="text-xs text-white-dark">10 Mar, 2020</p>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="shrink-0 ltr:mr-2 rtl:ml-2">
                            <div class="flex h-8 w-8 items-center justify-center rounded-full bg-dark text-white">
                                <icon-server class="h-4 w-4" />
                            </div>
                        </div>
                        <div>
                            <h5 class="font-semibold dark:text-white-light">Server rebooted successfully</h5>
                            <p class="text-xs text-white-dark">06 Apr, 2020</p>
                        </div>
                    </div>
                </div>
            </ng-scrollbar>
        </div>
    </div>

    <div class="mb-6 grid gap-6 sm:grid-cols-3 xl:grid-cols-5">
        <div class="panel h-full sm:col-span-3 xl:col-span-2">
            <div class="mb-5 flex items-start justify-between">
                <h5 class="text-lg font-semibold dark:text-white-light">Visitors by Browser</h5>
            </div>
            <div class="flex flex-col space-y-5">
                <div class="flex items-center">
                    <div class="h-9 w-9">
                        <div class="flex h-9 w-9 items-center justify-center rounded-xl bg-primary/10 text-primary dark:bg-primary dark:text-white-light">
                            <icon-chrome class="h-5 w-5" />
                        </div>
                    </div>
                    <div class="w-full flex-initial px-3">
                        <div class="w-summary-info mb-1 flex justify-between font-semibold text-white-dark">
                            <h6>Chrome</h6>
                            <p class="text-xs ltr:ml-auto rtl:mr-auto">65%</p>
                        </div>
                        <div>
                            <div class="h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
                                <div
                                    class="relative h-full w-full rounded-full bg-gradient-to-r from-[#009ffd] to-[#2a2a72] before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
                                    style="width: 65%"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="h-9 w-9">
                        <div class="flex h-9 w-9 items-center justify-center rounded-xl bg-danger/10 text-danger dark:bg-danger dark:text-white-light">
                            <icon-safari class="h-5 w-5" />
                        </div>
                    </div>
                    <div class="w-full flex-initial px-3">
                        <div class="w-summary-info mb-1 flex justify-between font-semibold text-white-dark">
                            <h6>Safari</h6>
                            <p class="text-xs ltr:ml-auto rtl:mr-auto">40%</p>
                        </div>
                        <div>
                            <div class="h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
                                <div
                                    class="relative h-full w-full rounded-full bg-gradient-to-r from-[#a71d31] to-[#3f0d12] before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
                                    style="width: 40%"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="h-9 w-9">
                        <div class="flex h-9 w-9 items-center justify-center rounded-xl bg-warning/10 text-warning dark:bg-warning dark:text-white-light">
                            <icon-globe class="h-5 w-5" />
                        </div>
                    </div>
                    <div class="w-full flex-initial px-3">
                        <div class="w-summary-info mb-1 flex justify-between font-semibold text-white-dark">
                            <h6>Others</h6>
                            <p class="text-xs ltr:ml-auto rtl:mr-auto">25%</p>
                        </div>
                        <div>
                            <div class="h-5 w-full overflow-hidden rounded-full bg-dark-light p-1 shadow-3xl dark:bg-dark-light/10 dark:shadow-none">
                                <div
                                    class="relative h-full w-full rounded-full bg-gradient-to-r from-[#fe5f75] to-[#fc9842] before:absolute before:inset-y-0 before:m-auto before:h-2 before:w-2 before:rounded-full before:bg-white ltr:before:right-0.5 rtl:before:left-0.5"
                                    style="width: 25%"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="panel h-full p-0">
            <div class="flex p-5">
                <div class="flex h-11 w-11 shrink-0 items-center justify-center rounded-xl bg-primary/10 text-primary dark:bg-primary dark:text-white-light">
                    <icon-users-group class="h-5 w-5" />
                </div>
                <div class="font-semibold ltr:ml-3 rtl:mr-3">
                    <p class="text-xl dark:text-white-light">31.6K</p>
                    <h5 class="text-xs text-[#506690]">Followers</h5>
                </div>
            </div>
            <div class="h-44 overflow-hidden">
                <apx-chart
                    [series]="followers.series"
                    [chart]="followers.chart"
                    [stroke]="followers.stroke"
                    [colors]="followers.colors"
                    [grid]="followers.grid"
                    [yaxis]="followers.yaxis"
                    [tooltip]="followers.tooltip"
                    class="rounded-lg bg-white dark:bg-black"
                >
                    <!-- [fill]="followers.fill" -->
                    <!-- loader -->
                    <div class="grid min-h-[176px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                        <span class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"></span>
                    </div>
                </apx-chart>
            </div>
        </div>

        <div class="panel h-full p-0">
            <div class="flex p-5">
                <div class="flex h-11 w-11 shrink-0 items-center justify-center rounded-xl bg-danger/10 text-danger dark:bg-danger dark:text-white-light">
                    <icon-link class="h-5 w-5" />
                </div>
                <div class="font-semibold ltr:ml-3 rtl:mr-3">
                    <p class="text-xl dark:text-white-light">1,900</p>
                    <h5 class="text-xs text-[#506690]">Referral</h5>
                </div>
            </div>
            <div class="h-44 overflow-hidden">
                <apx-chart
                    [series]="referral.series"
                    [chart]="referral.chart"
                    [stroke]="referral.stroke"
                    [colors]="referral.colors"
                    [grid]="referral.grid"
                    [yaxis]="referral.yaxis"
                    [tooltip]="referral.tooltip"
                    class="rounded-lg bg-white dark:bg-black"
                >
                    <!-- [fill]="referral.fill" -->
                    <!-- loader -->
                    <div class="grid min-h-[176px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                        <span class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"></span>
                    </div>
                </apx-chart>
            </div>
        </div>

        <div class="panel h-full p-0">
            <div class="flex p-5">
                <div class="flex h-11 w-11 shrink-0 items-center justify-center rounded-xl bg-success/10 text-success dark:bg-success dark:text-white-light">
                    <icon-chat-dots class="h-5 w-5" />
                </div>
                <div class="font-semibold ltr:ml-3 rtl:mr-3">
                    <p class="text-xl dark:text-white-light">18.2%</p>
                    <h5 class="text-xs text-[#506690]">Engagement</h5>
                </div>
            </div>
            <div class="h-44 overflow-hidden">
                <apx-chart
                    [series]="engagement.series"
                    [chart]="engagement.chart"
                    [stroke]="engagement.stroke"
                    [colors]="engagement.colors"
                    [grid]="engagement.grid"
                    [yaxis]="engagement.yaxis"
                    [tooltip]="engagement.tooltip"
                    class="rounded-lg bg-white dark:bg-black"
                >
                    <!-- [fill]="engagement.fill" -->
                    <!-- loader -->
                    <div class="grid min-h-[176px] place-content-center bg-white-light/30 dark:bg-dark dark:bg-opacity-[0.08]">
                        <span class="inline-flex h-5 w-5 animate-spin rounded-full border-2 border-black !border-l-transparent dark:border-white"></span>
                    </div>
                </apx-chart>
            </div>
        </div>
    </div>

    <div class="grid gap-6 md:grid-cols-2 xl:grid-cols-3">
        <div class="panel h-full">
            <div class="-m-5 mb-5 flex items-start border-b border-[#e0e6ed] p-5 dark:border-[#1b2e4b]">
                <div class="shrink-0 rounded-full ring-2 ring-white-light ltr:mr-4 rtl:ml-4 dark:ring-dark">
                    <img src="/assets/images/profile-1.jpeg" alt="" class="h-10 w-10 rounded-full object-cover" />
                </div>
                <div class="font-semibold">
                    <h6>Jimmy Turner</h6>
                    <p class="mt-1 text-xs text-white-dark">Monday, Nov 18</p>
                </div>
            </div>
            <div>
                <div class="pb-8 text-white-dark">
                    "Duis aute irure dolor" in reprehenderit in voluptate velit esse cillum "dolore eu fugiat" nulla pariatur. Excepteur sint occaecat cupidatat
                    non proident.
                </div>
                <div class="absolute bottom-0 -mx-5 flex w-full items-center justify-between p-5">
                    <div class="flex items-center">
                        <icon-thumb-up class="relative -top-0.5 inline h-5 w-5 text-info ltr:mr-1.5 rtl:ml-1.5" />
                        <span class="dark:text-info">551 Likes</span>
                    </div>
                    <a
                        href="javascript:;"
                        class="flex items-center rounded-md bg-success/30 px-1.5 py-1 text-xs text-success hover:shadow-[0_10px_20px_-10px] hover:shadow-success"
                        >Read More
                        <icon-carets-down class="h-4 w-4 -rotate-90 ltr:ml-1.5 rtl:mr-1.5 rtl:rotate-90" />
                    </a>
                </div>
            </div>
        </div>

        <div class="panel h-full">
            <div class="-m-5 mb-5 flex items-center justify-between border-b border-[#e0e6ed] p-5 dark:border-[#1b2e4b]">
                <div class="flex">
                    <div class="media-aside align-self-start">
                        <div class="shrink-0 rounded-full ring-2 ring-white-light ltr:mr-4 rtl:ml-4 dark:ring-dark">
                            <img src="/assets/images/g-8.png" alt="" class="h-10 w-10 rounded-full object-cover" />
                        </div>
                    </div>
                    <div class="font-semibold">
                        <h6>Dev Summit - New York</h6>
                        <p class="mt-1 text-xs text-white-dark">Bronx, NY</p>
                    </div>
                </div>
            </div>
            <div class="pb-8 text-center font-semibold">
                <div class="mb-4 text-primary">4 Members Going</div>
                <div class="flex items-center justify-center gap-3 pb-8">
                    <img
                        class="h-10 w-10 overflow-hidden rounded-lg object-cover ring-2 ring-white-light dark:ring-dark"
                        src="/assets/images/profile-1.jpeg"
                        alt=""
                    />
                    <img
                        class="h-10 w-10 overflow-hidden rounded-lg object-cover ring-2 ring-white-light dark:ring-dark"
                        src="/assets/images/profile-2.jpeg"
                        alt=""
                    />
                    <img
                        class="h-10 w-10 overflow-hidden rounded-lg object-cover ring-2 ring-white-light dark:ring-dark"
                        src="/assets/images/profile-3.jpeg"
                        alt=""
                    />
                    <img
                        class="h-10 w-10 overflow-hidden rounded-lg object-cover ring-2 ring-white-light dark:ring-dark"
                        src="/assets/images/profile-4.jpeg"
                        alt=""
                    />
                </div>

                <div class="absolute bottom-0 -mx-5 flex w-full items-center justify-between p-5">
                    <a href="javascript:;" class="btn btn-secondary btn-lg w-full border-0 bg-gradient-to-r from-[#3d38e1] to-[#1e9afe]">View Details</a>
                </div>
            </div>
        </div>

        <div class="panel h-full">
            <div class="-m-5 mb-5 flex items-center justify-between border-b border-[#e0e6ed] p-5 dark:border-[#1b2e4b]">
                <a href="javascript:;" class="flex font-semibold">
                    <div class="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-secondary text-white ltr:mr-4 rtl:ml-4">
                        <span>FD</span>
                    </div>
                    <div>
                        <h6>Figma Design</h6>
                        <p class="mt-1 text-xs text-white-dark">Design Reset</p>
                    </div>
                </a>
                <div hlMenu class="dropdown">
                    <button type="button" hlMenuButton>
                        <icon-horizontal-dots class="h-5 w-5 text-black/70 hover:!text-primary dark:text-white/70" />
                    </button>
                    <ul *hlMenuItems @toggleAnimation class="ltr:right-0 rtl:left-0">
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">View Project</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Edit Project</a>
                        </li>
                        <li>
                            <a href="javascript:;" *hlMenuItem="let menuItem">Mark as Done</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="group">
                <div class="mb-5">
                    <div class="text-white-dark">
                        Doloribus nisi vel suscipit modi, optio ex repudiandae voluptatibus officiis commodi. Nesciunt quas aut neque incidunt!
                    </div>
                </div>
                <div class="mb-2 flex items-center justify-between font-semibold">
                    <div class="flex items-center">
                        <icon-square-check class="h-4 w-4 text-success" />

                        <div class="text-xs ltr:ml-2 rtl:mr-2">5 Tasks</div>
                    </div>
                    <p class="text-primary">65%</p>
                </div>
                <div class="mb-5 h-2.5 rounded-full bg-dark-light p-0.5 dark:bg-dark-light/10">
                    <div class="h-full rounded-full bg-gradient-to-r from-[#1e9afe] to-[#60dfcd]" style="width: 65%"></div>
                </div>
                <div class="flex items-end justify-between">
                    <div class="flex items-center rounded-full bg-danger/20 px-2 py-1 text-xs font-semibold text-danger">
                        <icon-clock class="h-3 w-3 ltr:mr-1 rtl:ml-1" />

                        3 Days Left
                    </div>
                    <div class="flex items-center justify-center group-hover:-space-x-2 rtl:space-x-reverse rtl:group-hover:space-x-reverse">
                        <span
                            class="flex h-9 w-9 items-center justify-center rounded-full bg-[#bfc9d4] font-semibold text-white opacity-0 transition-all duration-300 group-hover:opacity-100 dark:bg-dark"
                            >+6</span
                        >
                        <img
                            class="h-9 w-9 rounded-full border-2 border-white object-cover transition-all duration-300 dark:border-dark"
                            src="/assets/images/profile-6.jpeg"
                            alt=""
                        />
                        <img
                            class="h-9 w-9 rounded-full border-2 border-white object-cover transition-all duration-300 dark:border-dark"
                            src="/assets/images/profile-7.jpeg"
                            alt=""
                        />
                        <img
                            class="h-9 w-9 rounded-full border-2 border-white object-cover transition-all duration-300 dark:border-dark"
                            src="/assets/images/profile-8.jpeg"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
