import { Component, Input, OnInit, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { IconComponentMap } from './icon-component-map'; // Import the icon-component map

@Component({
  selector: 'app-dynamic-icon',
  template: `<ng-container *ngComponentOutlet="dynamicIconComponent"></ng-container>`
})
export class DynamicIconComponent implements OnInit {
  @Input() iconName: any; // Input to pass the icon name

  dynamicIconComponent: any; // Variable to hold the resolved component type

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    // Resolve the component type using the icon name
    this.dynamicIconComponent = IconComponentMap[this.iconName];
  }
}
