<nav class="navbar navbar-expand-lg fixed-top trans-navigation">
    <div class="container-fluid">
        <a class="navbar-brand" routerLink="/">
            <img src="./../../../../assets/theme/images/LogoWhite.png" alt="" class="img-fluid b-logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNav"
            (click)="toggleMenu()" aria-controls="mainNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
                <i class="fa fa-bars"></i>
            </span>
        </button>

        <div class="navbar-collapse justify-content-end" id="mainNav">
            <ul class="navbar-nav ">

                <li class="nav-item" (click)="toggleMenu()">
                    <a class="nav-link smoth-scroll" routerLink="/" id="navbarWelcome">Home</a>
                </li>
                <li class="nav-item dropdown" [ngClass]="{'mobile-dropdown': isMobile}">
                    <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" href="#" (click)="toggleDropdown('products')">
                        Products<i class="fas fa-chevron-down ml-2"></i>
                    </a>
                    <ul class="dropdown-menu products" aria-labelledby="navbarWelcome" (click)="toggleMenu()">
                        <li>
                            <a class="dropdown-item" routerLink="/products/document-management">Document Management </a>
                        </li>
                        <li><a class="dropdown-item" routerLink="/products/risk-management">Risk Management </a></li>
                        <li><a class="dropdown-item" routerLink="/products/audit-management">Audit Management</a></li>
                        <li><a class="dropdown-item" routerLink="/products/corrective-active-management">Corrective
                                Action Management</a>
                        </li>
                        <li><a class="dropdown-item" routerLink="/products/maintanance-management">Calibration /
                                Maintenance
                                Management</a></li>
                        <li><a class="dropdown-item" routerLink="/products/qehs-monitoring">QEHS Monitoring</a></li>
                        <li><a class="dropdown-item" routerLink="/products/compliance-management">Compliance
                                Management</a></li>
                        <li><a class="dropdown-item" routerLink="/products/training">Training</a></li>
                        <li><a class="dropdown-item" routerLink="/products/improvement">Improvement</a></li>
                        <li><a class="dropdown-item" routerLink="/products/r2-operations">R2 Operations</a></li>

                    </ul>
                </li>

                <li class="nav-item dropdown Standards" [ngClass]="{'mobile-dropdown': isMobile}">
                    <a class="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false" href="#" (click)="toggleDropdown('standards')">
                        Standards<i class="fas fa-chevron-down ml-2"></i>
                    </a>
                    <ul class="dropdown-menu standards" aria-labelledby="navbarWelcome" (click)="toggleMenu()">
                        <li><a class="dropdown-item" routerLink="/standards/r2-standards">R2 Standard</a></li>
                        <li><a class="dropdown-item" routerLink="/standards/iso9001">ISO 9001</a></li>
                        <li><a class="dropdown-item" routerLink="/standards/iso14001">ISO 14001</a></li>
                        <li><a class="dropdown-item" routerLink="/standards/iso45001">ISO 45001 </a></li>
                    </ul>
                </li>
                <li class="nav-item" (click)="toggleMenu()">
                    <a class="nav-link smoth-scroll" routerLink="/about-us">About</a>
                </li>

                <li class="nav-item mr-5" (click)="toggleMenu()">
                    <a class="nav-link smoth-scroll" routerLink="/contact">Contact</a>
                </li>
                <li class="nav-item" (click)="toggleMenu()">
                    <a href="#/login">
                        <button id="Login" class="custom-btn login-btn nav-li-btn"><span>Login</span></button>
                    </a>
                    <a href="#/signup">
                        <button id="Signup" class="custom-btn signup-btn nav-li-btn"><span>Sign
                                up</span></button>
                    </a>
                </li>
                
            </ul>
        </div>
    </div>
</nav>