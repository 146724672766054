<!--  FOOTER AREA START  -->
<section id="footer" class="section-padding">
    <div class="container-fluid">
        <div class="row justify-content-around pb-8">
            <div class="col-md-3">
                <div class="footer-widget footer-link">
                    <a class="navbar-brand" routerLink="/">
                        <img src="./../../../../assets/theme/images/LogoWhite.png" alt="" class="img-fluid b-logo">
                    </a>
                    <h4>QEHS Software to simplify your management system.</h4>
                    <p>
                        "Setting the global standard for excellence: Standards bringing Quality to Life."
                    </p>
                </div>
            </div>

            <div class="col-md-3">
                <div class="footer-widget footer-link">
                    <h4>Our Products</h4>
                    <ul>
                        <li><a routerLink="/#!">Document Management</a></li>
                        <li><a routerLink="/#!">Risk Management</a></li>
                        <li><a routerLink="/#!">Audit Management</a></li>
                        <li><a routerLink="/#!">Corrective Action Management</a></li>
                        <li><a routerLink="/#!">Calibration / Maintenance Management</a></li>
                        <li><a routerLink="/#!">QEHS Monitoring</a></li>
                        <li><a routerLink="/#!">Compliance Management</a></li>
                        <li><a routerLink="/#!">Training</a></li>
                        <li><a routerLink="/#!">Improvement</a></li>
                        <li><a routerLink="/#!">R2 Operations</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-1">
                <div class="footer-widget footer-link">
                    <h4>Standards</h4>
                    <ul>
                        <li><a routerLink="/standards/r2-standards">R2 Standard</a></li>
                        <li><a routerLink="/standards/iso9001">ISO 9001</a></li>
                        <li><a routerLink="/standards/iso14001">ISO 14001</a></li>
                        <li><a routerLink="/standards/iso45001">ISO 45001</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-1">
                <div class="footer-widget footer-link">
                    <h4>About</h4>
                    <ul>
                        <li><a routerLink="/about-us">About</a></li>
                        <li><a routerLink="/service">Service</a></li>
                        <li><a routerLink="/pricing">Pricing</a></li>
                        <li><a routerLink="/#!">Team</a></li>
                        <li><a routerLink="/#!">Testimonials</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                    </ul>
                </div>
            </div>

            
            
            <div class="col-md-2">
                <div class="footer-widget footer-link">
                    <h4>Quick Links</h4>
                    <ul>
                        <li><a routerLink="/#!">How it Works</a></li>
                        <li><a routerLink="/#!">Support</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/#!">Report Bug</a></li>
                        <li><a routerLink="/#!">License</a></li>
                        <li><a routerLink="/terms-and-condition">Terms & Condition</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-2">
                <div class="footer-widget footer-text">
                    <h4>Contact Info</h4>
                    <p class="mail"><span>Mail: </span> info@p3logiq.com</p>
                    <p><span>Phone :</span> +1 (833) 675-8007</p>
                    <p><span>Location:</span> </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 text-center">
                <div class="footer-copy">
                    Copyright &copy; 2024, Designed &amp; All Right Reserved <a href="https://bluelogiq.com" target="_blank">BlueLogiQ</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!--  FOOTER AREA END  -->