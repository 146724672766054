import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-website-header',
  templateUrl: './website-header.component.html',
  styleUrls: ['./website-header.component.css']
})
export class WebsiteHeaderComponent implements OnInit {
  isMobile: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.checkIfMobile();
  }

  @HostListener('window:resize', ['$event'])
  checkIfMobile() {
    this.isMobile = window.innerWidth < 992; // Set breakpoint for mobile view
  }

  toggleDropdown(cls: any) {
    if (this.isMobile) {
      const dropdown: any = document.querySelector('.mobile-dropdown .dropdown-menu.' + cls);
      dropdown.classList.toggle('show');
    }
  }

  toggleMenu(): void {
    if (this.isMobile) {
      const mainNav: any = document.querySelector("#mainNav");
      mainNav.classList.toggle("show");
    }
  }
}
